const state = {
    optRoute: '/opt/setup',
    scdRoute: '/scd/setup',
    previousTimeOVT: '',
    previousTimeFVT: '',
    playbackSpeedFVT: 1,
    playbackSpeed: 1,
    startDate: '',
    endDate: '',
    emptyFilter: true,
    currentDate: '',
    navigationVisible: false,
    tool: '',
    toolTitle: '',
    loadingObservatoryList: false,
    observatoryList: [],
    selectedObservatory: {
        code: null,
        alt: 0,
        id: 0,
        latitude: 51.47793,
        longitude: 0,
        name: '',
    },
    observatoryElevation: 2000,
    recentlySelectedObservatory: {},
    timeFrame: {
        start: null,
        end: null,
        outOfShortTerm: false,
        dateTime: {
            start: null,
            end: null
        },
        converted: {
            start: null,
            end: null
        }
    },
    timestep: {
        step: null,
        unit: null,
    },
    timestepMemory: null,
    spinnerShow: false,
    requestCancel: false,
    popupShow: false,
    popupConfig: {},
    objects: [],
    objectsSelected: [],
    objectsInitialLoadPerformed: false,
    filters: {},
    dropboxSetupId: '',
    loadingAsteroidsList: false,
    availableAsteroidsList: [],
    finalAsteroidsList: [],
    syntheticDefaultObjectsList: [],
    syntheticFvtObjectsList: [],
    insertAsteroidsList: '',
    asteroidsNames: '',
    configurationPopupMode: 'select-list',
    paginationFrom: 0,
    token: '',
    tokenCancelled: '',
    userDefinedObjects: false,
    setupMemory: {},
    ephemerides: {
        objects: {},
        fields: [],
        units: [],
    },
    ephemeridesFiltered: {
        objects: {},
        fields: [],
        units: [],
    },
    ephemeridesShortterm: {
        objects: {},
        fields: [],
        units: [],
    },
    ephemeridesActiveRow: '',
    ephemeridesColumns: [],
    availableEphemeridesColumns: [],
    finalEphemeridesColumns: [],
    ephemeridesOnlyObservable: false,
    shorttermDate: null,
    calculationProgress: 0,
    calculationEstimatedTime: null,
    calculatedObjects: null,
    longtermObjectName: '',
    longtermActivePlots: [],
    longtermObservablePlots: [],
    longtermEphemerides: {},
    longtermVisualiseOnChart: true,
    lastCalculatedEphemerides: false,
    longtermCurrentPlot: '',
    multiselectOptions: {},
    enableOptFiltering: false,
    enableOptResults: false,
    timezone: 'GMT +00:00',
    skychartEnableVisualisation: false,
    skychartObjectsShow: true,
    skychartMagNumber: [0, 0, 0, 0, 0, 0],
    skychartActiveObjectName: '',
    skychartSettingsPanelName: '',
    skychartSettings: null,
    skychartLoadedObjects: [],
    skychartExceedPathAngleObjects: [],
    skychartTraceObjects: {},
    skychartTraceDate: null,
    skychart: null,
    skychartObjectTracking: true,
    skychartObjectDmsFormat: null,
    skychartObjectsDetailsHide: true,    
    isChecked: [],
    isPerturbed: [],
    selectedObjectName: '',
    riskListChecked: false,
    orbitVisualisationSelects: {},
    orbitVisualisationActiveSelects: {},
    loadingObjectsEphemerides: [],
    orbitVisualisationNow: new Date(),
    simulationTime: new Date(),
    orbitSettings: null,
    orbitSettingsOVT: null,
    orbitSettingsFVT: null,
    orbitSettingsPanelName: '',
    keplerianData: [],
    perturbedData: [],
    synodicPerturbedData: [],
    orbitActiveObject: null,
    orbitViewerFocusedOnObject: false,
    orbitRecorderShow: false,
    orbitRecorderOnAir: false,
    mainBody: [],
    syntheticMainBody: null,
    closeApproaches: [],
    closeApproachesRequest: null,
    selectedCloseApproach: null,
    flybyTimeframe: null,
    flybyClosestPoint: null,
    minCameraDistance: null,
    zoom: null,
    zoomOVT: 6.25,
    zoomFVT: 6.25,
    synodicOrbitChartSettings: {
        daysNumberMode: false,
    },
    synodicOrbitChartsData: {
        date: [],
        startDateJulian: null,
        endDateJulian: null,
        objects: {},
    },
    sovtFocusedObject: 'sun',
    ovtFocusedObject: 'sun',
    fvtFocusedObject: 'mainbody',
    calculatedRealSynodicObjectsList: [],
    selectedSynodicObjectName: '',
    visibleRealSynodicObjectsList: [],
    perturbedSynodicOrbitList: [],
    synodicActiveObject: null,
    detectionPolarPoints: null,
    synodicSpeed: 7,
    impactorsList: null,
    isNoApproachFoundMsgVisible: false,
    isSyntheticObjectFlybyAboveLdLimit: false,
    restoreFlybyTime: '',
};

const getters = {
    multiselectOptions: (state) => {
        return state.multiselectOptions;
    },
    scdRoute: (state) => {
        return state.scdRoute;
    },
    optRoute: (state) => {
        return state.optRoute;
    },
    previousTimeOVT: (state) => {
        return state.previousTimeOVT;
    },
    previousTimeFVT: (state) => {
        return state.previousTimeFVT;
    },
    playbackSpeedFVT: (state) => {
        return state.playbackSpeedFVT;
    },
    playbackSpeed: (state) => {
        return state.playbackSpeed;
    },
    startDate: (state) => {
        return state.startDate;
    },
    endDate: (state) => {
        return state.endDate;
    },
    emptyFilter: (state) => {
        return state.emptyFilter;
    },
    currentDate: (state) => {
        return state.currentDate;
    },
    navigationVisible: (state) => {
        return state.navigationVisible;
    },
    tool: (state) => {
        return state.tool;
    },
    toolTitle: (state) => {
        return state.toolTitle;
    },
    loadingObservatoryList: (state) => {
        return state.loadingObservatoryList;
    },
    observatoryList: (state) => {
        return state.observatoryList;
    },
    observatoryElevation: (state) => {
        return state.observatoryElevation;
    },
    selectedObservatory: (state) => {
        return state.selectedObservatory;
    },
    recentlySelectedObservatory: (state) => {
        return state.recentlySelectedObservatory;
    },
    timeFrame: (state) => {
        return state.timeFrame;
    },
    timestep: (state) => {
        return state.timestep;
    },
    timestepMemory: (state) => {
        return state.timestepMemory;
    },
    spinnerShow: (state) => {
        return state.spinnerShow;
    },
    requestCancel: (state) => {
        return state.requestCancel;
    },
    popupShow: (state) => {
        return state.popupShow;
    },
    popupConfig: (state) => {
        return state.popupConfig;
    },
    objects: (state) => {
        return state.objects;
    },
    objectsSelected: (state) => {
        return state.objectsSelected;
    },
    objectsInitialLoadPerformed: (state) => {
        return state.objectsInitialLoadPerformed;
    },
    filters: (state) => {
        return state.filters;
    },
    dropboxSetupId: (state) => {
        return state.dropboxSetupId;
    },
    loadingAsteroidsList: (state) => {
        return state.loadingAsteroidsList;
    },
    availableAsteroidsList: (state) => {
        return state.availableAsteroidsList;
    },
    finalAsteroidsList: (state) => {
        return state.finalAsteroidsList;
    },
    syntheticDefaultObjectsList: (state) => {
        return state.syntheticDefaultObjectsList;
    },
    syntheticFvtObjectsList: (state) => {
        return state.syntheticFvtObjectsList;
    },
    insertAsteroidsList: (state) => {
        return state.insertAsteroidsList;
    },
    asteroidsNames: (state) => {
        return state.asteroidsNames;
    },
    configurationPopupMode: (state) => {
        return state.configurationPopupMode;
    },
    paginationFrom: (state) => {
        return state.paginationFrom;
    },
    paginationLoading: (state) => {
        return state.paginationLoading;
    },
    token: (state) => {
        return state.token;
    },
    tokenCancelled: (state) => {
        return state.tokenCancelled;
    },
    userDefinedObjects: (state) => {
        return state.userDefinedObjects;
    },
    setupMemory: (state) => {
        return state.setupMemory;
    },
    toolState: (state) => {
        return state;
    },
    ephemerides: (state) => {
        return state.ephemerides;
    },
    ephemeridesFiltered: (state) => {
        return state.ephemeridesFiltered;
    },
    ephemeridesShortterm: (state) => {
        return state.ephemeridesShortterm;
    },
    ephemeridesActiveRow: (state) => {
        return state.ephemeridesActiveRow;
    },
    ephemeridesColumns: (state) => {
        return state.ephemeridesColumns;
    },
    availableEphemeridesColumns: (state) => {
        return state.availableEphemeridesColumns;
    },
    finalEphemeridesColumns: (state) => {
        return state.finalEphemeridesColumns;
    },
    ephemeridesOnlyObservable: (state) => {
        return state.ephemeridesOnlyObservable;
    },
    shorttermDate: (state) => {
        return state.shorttermDate;
    },
    calculationProgress: (state) => {
        return state.calculationProgress;
    },
    calculationEstimatedTime: (state) => {
        return state.calculationEstimatedTime;
    },
    calculatedObjects: (state) => {
        return state.calculatedObjects;
    },
    longtermObjectName: (state) => {
        return state.longtermObjectName;
    },
    longtermActivePlots: (state) => {
        return state.longtermActivePlots;
    },
    longtermObservablePlots: (state) => {
        return state.longtermObservablePlots;
    },
    longtermEphemerides: (state) => {
        return state.longtermEphemerides;
    },
    longtermVisualiseOnChart: (state) => {
        return state.longtermVisualiseOnChart;
    },
    lastCalculatedEphemerides: (state) => {
        return state.lastCalculatedEphemerides;
    },
    longtermCurrentPlot: (state) => {
        return state.longtermCurrentPlot;
    },
    enableOptFiltering: (state) => {
        return state.enableOptFiltering;
    },
    enableOptResults: (state) => {
        return state.enableOptResults;
    },
    timezone: (state) => {
        return state.timezone;
    },
    skychartEnableVisualisation: (state) => {
        return state.skychartEnableVisualisation;
    },
    skychartObjectsShow: (state) => {
        return state.skychartObjectsShow;
    },
    skychartMagNumber: (state) => {
        return state.skychartMagNumber;
    },
    orbitVisualisationObjectsShow: (state) => {
        return state.orbitVisualisationObjectsShow;
    },
    skychartActiveObjectName: (state) => {
        return state.skychartActiveObjectName;
    },
    skychartSettingsPanelName: (state) => {
        return state.skychartSettingsPanelName;
    },
    skychartSettings: (state) => {
        return state.skychartSettings;
    },
    skychartLoadedObjects: (state) => {
        return state.skychartLoadedObjects;
    },    
    skychartExceedPathAngleObjects: (state) => {
        return state.skychartExceedPathAngleObjects;                     
    },
    skychartTraceObjects: (state) => {
        return state.skychartTraceObjects;
    },
    skychartTraceDate: (state) => {
        return state.skychartTraceDate;
    },
    skychartObjectTracking: (state) => {
        return state.skychartObjectTracking;
    },
    skychartDmsFormat: (state) => {
        return state.skychartDmsFormat;
    },
    skychartObjectsDetailsHide: (state) => {
        return state.skychartObjectsDetailsHide;
    },
    isChecked: (state) => {
        return state.isChecked;
    },
    isPerturbed: (state) => {
        return state.isPerturbed;
    },
    selectedObjectName: (state) => {
        return state.selectedObjectName;
    },
    loadingObjectsEphemerides: (state) => {
        return state.loadingObjectsEphemerides;
    },
    riskListChecked: (state) => {
        return state.riskListChecked;
    },
    orbitVisualisationSelects: (state) => {
        return state.orbitVisualisationSelects;
    },
    orbitVisualisationActiveSelects: (state) => {
        return state.orbitVisualisationActiveSelects;
    },
    orbitVisualisationNow: (state) => {
        return state.orbitVisualisationNow;
    },
    simulationTime: (state) => {
        return state.simulationTime;
    },
    orbitSettings: (state) => {
        return state.orbitSettings;
    },
    orbitSettingsOVT: (state) => {
        return state.orbitSettingsOVT;
    },
    orbitSettingsFVT: (state) => {
        return state.orbitSettingsFVT;
    },
    orbitSettingsPanelName: (state) => {
        return state.orbitSettingsPanelName;
    },
    keplerianData: (state) => {
        return state.keplerianData;
    },
    perturbedData: (state) => {
        return state.perturbedData;
    },
    synodicPerturbedData: (state) => {
        return state.synodicPerturbedData;
    },
    orbitActiveObject: (state) => {
        return state.orbitActiveObject;
    },
    orbitViewerFocusedOnObject: (state) => {
        return state.orbitViewerFocusedOnObject;
    },
    orbitRecorderShow: (state) => {
        return state.orbitRecorderShow;
    },
    orbitRecorderOnAir: (state) => {
        return state.orbitRecorderOnAir;
    },
    mainBody: (state) => {
        return state.mainBody;
    },
    syntheticMainBody: (state) => {
        return state.syntheticMainBody;
    },
    closeApproachesRequest: (state) => {
        return state.closeApproachesRequest;
    },
    closeApproaches: (state) => {
        return state.closeApproaches;
    },
    selectedCloseApproach: (state) => {
        return state.selectedCloseApproach;
    },
    closeApproachDetected: (state) => {
        return state.closeApproachDetected;
    },
    isNoApproachFoundMsgVisible: (state) => {
        return state.isNoApproachFoundMsgVisible;
    },
    isSyntheticObjectFlybyAboveLdLimit: (state) => {
        return state.isSyntheticObjectFlybyAboveLdLimit;
    },
    restoreFlybyTime: (state) => {
        return state.restoreFlybyTime;
    },
    flybyTimeframe: (state) => {
        return state.flybyTimeframe;
    },
    flybyClosestPoint: (state) => {
        return state.flybyClosestPoint;
    },
    minCameraDistance: (state) => {
        return state.minCameraDistance;
    },
    zoom: (state) => {
        return state.zoom;
    },
    synodicOrbitChartSettings: (state) => {
        return state.synodicOrbitChartSettings;
    },
    zoomOVT: (state) => {
        return state.zoomOVT;
    },
    zoomFVT: (state) => {
        return state.zoomFVT;
    },
    synodicOrbitChartsData: (state) => {
        return state.synodicOrbitChartsData;
    },
    selectedSynodicObjectName: (state) => {
        return state.selectedSynodicObjectName;
    },
    calculatedRealSynodicObjectsList: (state) => {
        return state.calculatedRealSynodicObjectsList;
    },
    visibleRealSynodicObjectsList: (state) => {
        return state.visibleRealSynodicObjectsList;
    },
    sovtFocusedObject: (state) => {
        return state.sovtFocusedObject;
    },
    ovtFocusedObject: (state) => {
        return state.ovtFocusedObject;
    },
    fvtFocusedObject: (state) => {
        return state.fvtFocusedObject;
    },
    perturbedSynodicOrbitList: (state) => {
        return state.perturbedSynodicOrbitList;
    },
    synodicActiveObject: (state) => {
        return state.synodicActiveObject;
    },
    detectionPolarPoints: (state) => {
        return state.detectionPolarPoints;
    },
    synodicSpeed: (state) => {
        return state.synodicSpeed;
    },
    impactorsList: (state) => {
        return state.impactorsList;
    },
};

const mutations = {
    setStateProp(state, data) {
        state[data.prop] = data.value;
    },
    setState(state, obj) {
        Object.assign(state, obj);
    },
};

const actions = {
    setMultiselectOptions: (context, value) => {
        return context.commit('setStateProp', { prop: 'multiselectOptions', value });
    },
    setScdRoute(context, value) {
        return context.commit('setStateProp', { prop: 'scdRoute', value });
    },
    setOptRoute(context, value) {
        return context.commit('setStateProp', { prop: 'optRoute', value });
    },
    setPreviousTimeOVT(context, value) {
        return context.commit('setStateProp', { prop: 'previousTimeOVT', value });
    },
    setPreviousTimeFVT(context, value) {
        return context.commit('setStateProp', { prop: 'previousTimeFVT', value });
    },
    setPlaybackSpeedFVT(context, value) {
        return context.commit('setStateProp', { prop: 'playbackSpeedFVT', value });
    },
    setPlaybackSpeed(context, value) {
        return context.commit('setStateProp', { prop: 'playbackSpeed', value });
    },
    setStartDate(context, value) {
        return context.commit('setStateProp', { prop: 'startDate', value });
    },
    setEndDate(context, value) {
        return context.commit('setStateProp', { prop: 'endDate', value });
    },
    setEmptyFilter(context, value) {
        return context.commit('setStateProp', { prop: 'emptyFilter', value });
    },
    setCurrentDate(context, value) {
        return context.commit('setStateProp', { prop: 'currentDate', value });
    },
    setNavigationVisible(context, value) {
        return context.commit('setStateProp', { prop: 'navigationVisible', value });
    },
    setTool(context, value) {
        return context.commit('setStateProp', { prop: 'tool', value });
    },
    setToolTitle(context, value) {
        return context.commit('setStateProp', { prop: 'toolTitle', value });
    },
    setLoadingObservatoryList(context, value) {
        return context.commit('setStateProp', { prop: 'loadingObservatoryList', value });
    },
    setObservatoryList(context, value) {
        return context.commit('setStateProp', { prop: 'observatoryList', value });
    },
    setSelectedObservatory({commit}, value) {
        if (state.selectedObservatory.code !== '500') {
            commit('setStateProp', { prop: 'recentlySelectedObservatory', value: state.selectedObservatory });
        }
        commit('setStateProp', { prop: 'selectedObservatory', value });
    },
    setObservatoryElevation(context, value) {
        return context.commit('setStateProp', { prop: 'observatoryElevation', value });
    },
    setTimeFrame(context, value) {
        return context.commit('setStateProp', { prop: 'timeFrame', value });
    },
    setTimestep(context, value) {
        return context.commit('setStateProp', { prop: 'timestep', value });
    },
    setTimestepMemory(context, value) {
        return context.commit('setStateProp', { prop: 'timestepMemory', value });
    },
    setSpinnerShow(context, value) {
        return context.commit('setStateProp', { prop: 'spinnerShow', value });
    },
    setRequestCancel(context, value) {
        return context.commit('setStateProp', { prop: 'requestCancel', value });
    },
    setPopupShow({commit}, value) {
        commit('setStateProp', { prop: 'popupConfig', value: value.config });
        commit('setStateProp', { prop: 'popupShow', value: value.show });
    },
    setObjects(context, value) {
        return context.commit('setStateProp', { prop: 'objects', value });
    },
    setObjectsSelected(context, value) {
        return context.commit('setStateProp', { prop: 'objectsSelected', value });
    },
    setObjectsInitialLoadPerformed(context, value) {
        return context.commit('setStateProp', { prop: 'objectsInitialLoadPerformed', value });
    },
    setFilters(context, value) {
        return context.commit('setStateProp', { prop: 'filters', value });
    },
    setDropboxSetupId(context, value) {
        return context.commit('setStateProp', { prop: 'dropboxSetupId', value });
    },
    setLoadingAsteroidsList(context, value) {
        return context.commit('setStateProp', { prop: 'loadingAsteroidsList', value });
    },
    setAvailableAsteroidsList(context, value) {
        return context.commit('setStateProp', { prop: 'availableAsteroidsList', value });
    },
    setFinalAsteroidsList(context, value) {
        return context.commit('setStateProp', { prop: 'finalAsteroidsList', value });
    },
    setSyntheticDefaultObjectsList(context, value) {
        return context.commit('setStateProp', { prop: 'syntheticDefaultObjectsList', value });
    },
    setSyntheticFvtObjectsList(context, value) {
        return context.commit('setStateProp', { prop: 'syntheticFvtObjectsList', value });
    },
    setInsertAsteroidsList(context, value) {
        return context.commit('setStateProp', { prop: 'insertAsteroidsList', value });
    },
    setAsteroidsNames(context, value) {
        return context.commit('setStateProp', { prop: 'asteroidsNames', value });
    },
    setConfigurationPopupMode(context, value) {
        return context.commit('setStateProp', { prop: 'configurationPopupMode', value });
    },
    setPaginationFrom(context, value) {
        return context.commit('setStateProp', { prop: 'paginationFrom', value });
    },
    setPaginationLoading(context, value) {
        return context.commit('setStateProp', { prop: 'paginationLoading', value });
    },
    setToken(context, value) {
        return context.commit('setStateProp', { prop: 'token', value });
    },
    setTokenCancelled(context, value) {
        return context.commit('setStateProp', { prop: 'tokenCancelled', value });
    },
    setUserDefinedObjects(context, value) {
        return context.commit('setStateProp', { prop: 'userDefinedObjects', value });
    },
    setSetupMemory(context, value) {
        return context.commit('setStateProp', { prop: 'setupMemory', value });
    },
    setToolState({ commit }, stateObject) {
        commit('setState', stateObject);
    },
    setEphemerides(context, value) {
        return context.commit('setStateProp', { prop: 'ephemerides', value });
    },
    setEphemeridesFiltered(context, value) {
        return context.commit('setStateProp', { prop: 'ephemeridesFiltered', value });
    },
    setEphemeridesShortterm(context, value) {
        return context.commit('setStateProp', { prop: 'ephemeridesShortterm', value });
    },
    setEphemeridesActiveRow(context, value) {
        return context.commit('setStateProp', { prop: 'ephemeridesActiveRow', value });
    },
    setEphemeridesColumns(context, value) {
        return context.commit('setStateProp', { prop: 'ephemeridesColumns', value });
    },
    setAvailableEphemeridesColumns(context, value) {
        return context.commit('setStateProp', { prop: 'availableEphemeridesColumns', value });
    },
    setFinalEphemeridesColumns(context, value) {
        return context.commit('setStateProp', { prop: 'finalEphemeridesColumns', value });
    },
    setEphemeridesOnlyObservable(context, value) {
        return context.commit('setStateProp', { prop: 'ephemeridesOnlyObservable', value });
    },
    setShorttermDate(context, value) {
        return context.commit('setStateProp', { prop: 'shorttermDate', value });
    },
    setCalculationProgress(context, value) {
        return context.commit('setStateProp', { prop: 'calculationProgress', value });
    },
    setCalculationEstimatedTime(context, value) {
        return context.commit('setStateProp', { prop: 'calculationEstimatedTime', value });
    },
    setCalculatedObjects(context, value) {
        return context.commit('setStateProp', { prop: 'calculatedObjects', value });
    },
    setLongtermObjectName(context, value) {
        return context.commit('setStateProp', { prop: 'longtermObjectName', value });
    },
    setLongtermActivePlots(context, value) {
        return context.commit('setStateProp', { prop: 'longtermActivePlots', value });
    },
    setLongtermObservablePlots(context, value) {
        return context.commit('setStateProp', { prop: 'longtermObservablePlots', value });
    },
    setLongtermEphemerides(context, value) {
        return context.commit('setStateProp', { prop: 'longtermEphemerides', value });
    },
    setLongtermVisualiseOnChart(context, value) {
        return context.commit('setStateProp', { prop: 'longtermVisualiseOnChart', value });
    },
    setLastCalculatedEphemerides(context, value) {
        return context.commit('setStateProp', { prop: 'lastCalculatedEphemerides', value });
    },
    setLongtermCurrentPlot(context, value) {
        return context.commit('setStateProp', { prop: 'longtermCurrentPlot', value });
    },
    setEnableOptFiltering(context, value) {
		return context.commit('setStateProp', { prop: 'enableOptFiltering', value });
    },
	setEnableOptResults(context, value) {
        return context.commit('setStateProp', { prop: 'enableOptResults', value });
    },
    setTimezone(context, value) {
        return context.commit('setStateProp', { prop: 'timezone', value });
    },
    setSkychartEnableVisualisation(context, value) {
        return context.commit('setStateProp', { prop: 'skychartEnableVisualisation', value });
    },
    setSkychartObjectsShow(context, value) {
        return context.commit('setStateProp', { prop: 'skychartObjectsShow', value });
    },
    setSkychartMagNumber(context, value) {
        return context.commit('setStateProp', { prop: 'skychartMagNumber', value });
    },
    setOrbitVisualisationObjectsShow(context, value) {
        return context.commit('setStateProp', { prop: 'orbitVisualisationObjectsShow', value });
    },
    setSkychartActiveObjectName(context, value) {
        return context.commit('setStateProp', { prop: 'skychartActiveObjectName', value });
    },
    setSkychartSettingsPanelName(context, value) {
        return context.commit('setStateProp', { prop: 'skychartSettingsPanelName', value });
    },
    setSkychartSettings(context, value) {
        return context.commit('setStateProp', { prop: 'skychartSettings', value });
    },
    setSkychartLoadedObjects(context, value) {
        return context.commit('setStateProp', { prop: 'skychartLoadedObjects', value });
    },
    setSkychartExceedPathAngleObjects(context, value) {
        return context.commit('setStateProp', { prop: 'skychartExceedPathAngleObjects', value });
    },
    setSkychartTraceObjects(context, value) {
        return context.commit('setStateProp', { prop: 'skychartTraceObjects', value });
    },
    setSkychartTraceDate(context, value) {
        return context.commit('setStateProp', { prop: 'skychartTraceDate', value });
    },
    setSkychartObjectTracking(context, value) {
        return context.commit('setStateProp', { prop: 'skychartObjectTracking', value });
    },
    setSkychartDmsFormat(context, value) {
        return context.commit('setStateProp', { prop: 'skychartDmsFormat', value });
    },
    setSkychartObjectsDetailsHide(context, value) {
        return context.commit('setStateProp', { prop: 'skychartObjectsDetailsHide', value});
    },
    setIsChecked(context, value) {
        return context.commit('setStateProp', { prop: 'isChecked', value });
    },
    setIsPerturbed(context, value) {
        return context.commit('setStateProp', { prop: 'isPerturbed', value });
    },
    setSelectedObjectName(context, value) {
        return context.commit('setStateProp', { prop: 'selectedObjectName', value });
    },
    setLoadingObjectsEphemerides(context, value) {
        return context.commit('setStateProp', { prop: 'setLoadingObjectsEphemerides', value });
    },
    setRiskListChecked(context, value) {
        return context.commit('setStateProp', { prop: 'riskListChecked', value});
    },
    setOrbitVisualisationActiveSelects(context) {
        const value = Object.assign({}, state.orbitVisualisationActiveSelects);
        return context.commit('setStateProp', { prop: 'orbitVisualisationActiveSelects', value});
    },
    setOrbitVisualisationNow(context, value) {
        return context.commit('setStateProp', { prop: 'orbitVisualisationNow', value});
    },
    setSimulationTime(context, value) {
        return context.commit('setStateProp', { prop: 'simulationTime', value});
    },
    setOrbitSettings(context, value) {
        return context.commit('setStateProp', { prop: 'orbitSettings', value});
    },
    setOrbitSettingsOVT(context, value) {
        return context.commit('setStateProp', { prop: 'orbitSettingsOVT', value});
    },
    setOrbitSettingsFVT(context, value) {
        return context.commit('setStateProp', { prop: 'orbitSettingsFVT', value});
    },
    setOrbitSettingsPanelName(context, value) {
        return context.commit('setStateProp', { prop: 'orbitSettingsPanelName', value});
    },
    setKeplerianData(context, value) {
        return context.commit('setStateProp', { prop: 'keplerianData', value});
    },
    setPerturbedData(context, value) {
        return context.commit('setStateProp', { prop: 'perturbedData', value});
    },
    setSynodicPerturbedData(context, value) {
        return context.commit('setStateProp', { prop: 'synodicPerturbedData', value});
    },
    setOrbitActiveObject(context, value) {
        return context.commit('setStateProp', { prop: 'orbitActiveObject', value});
    },
    setOrbitViewerFocusedOnObject(context, value) {
        return context.commit('setStateProp', { prop: 'orbitViewerFocusedOnObject', value});
    },
    setOrbitRecorderShow(context, value) {
        return context.commit('setStateProp', { prop: 'orbitRecorderShow', value});
    },
    setOrbitRecorderOnAir(context, value) {
        return context.commit('setStateProp', { prop: 'orbitRecorderOnAir', value});
    },
    setMainBody(context, value) {
        return context.commit('setStateProp', { prop: 'mainBody', value});
    },
    setSyntheticMainBody(context, value) {
        return context.commit('setStateProp', { prop: 'syntheticMainBody', value});
    },
    setCloseApproaches(context, value) {
        return context.commit('setStateProp', { prop: 'closeApproaches', value});
    },
    setCloseApproachesRequest(context, value) {
        return context.commit('setStateProp', { prop: 'closeApproachesRequest', value});
    },
    setSelectedCloseApproach(context, value) {
        return context.commit('setStateProp', { prop: 'selectedCloseApproach', value});
    },
    setCloseApproachDetected(context, value) {
        return context.commit('setStateProp', { prop: 'closeApproachDetected', value});
    },
    setIsNoApproachFoundMsgVisible(context, value) {
        return context.commit('setStateProp', { prop: 'isNoApproachFoundMsgVisible', value });
    },
    setIsSyntheticObjectFlybyAboveLdLimit(context, value) {
        return context.commit('setStateProp', { prop: 'isSyntheticObjectFlybyAboveLdLimit', value });
    },
    setRestoreFlybyTime(context, value) {
        return context.commit('setStateProp', { prop: 'restoreFlybyTime', value });
    },
    setFlybyTimeframe(context, value) {
        return context.commit('setStateProp', { prop: 'flybyTimeframe', value});
    },
    setFlybyClosestPoint(context, value) {
        return context.commit('setStateProp', { prop: 'flybyClosestPoint', value});
    },
    setMinCameraDistance(context, value) {
        return context.commit('setStateProp', { prop: 'minCameraDistance', value});
    },
    setZoom(context, value) {
        return context.commit('setStateProp', { prop: 'zoom', value});
    },
    setZoomOVT(context, value) {
        return context.commit('setStateProp', { prop: 'zoomOVT', value});
    },
    setZoomFVT(context, value) {
        return context.commit('setStateProp', { prop: 'zoomFVT', value});
    },
    setSynodicOrbitChartSettings(context, value) {
        return context.commit('setStateProp', { prop: 'synodicOrbitChartSettings', value});
    },
    setSynodicOrbitChartsData(context, value) {
        return context.commit('setStateProp', { prop: 'synodicOrbitChartsData', value });
    },
    setSelectedSynodicObjectName(context, value) {
        return context.commit('setStateProp', { prop: 'selectedSynodicObjectName', value });
    },
    setCalculatedRealSynodicObjectsList(context, value) {
        return context.commit('setStateProp', { prop: 'calculatedRealSynodicObjectsList', value });
    },
    setVisibleRealSynodicObjectsList(context, value) {
        return context.commit('setStateProp', { prop: 'visibleRealSynodicObjectsList', value });
    },
    setSovtFocusedObject(context, value) {
        return context.commit('setStateProp', { prop: 'sovtFocusedObject', value});
    },
    setOvtFocusedObject(context, value) {
        return context.commit('setStateProp', { prop: 'ovtFocusedObject', value});
    },
    setFvtFocusedObject(context, value) {
        return context.commit('setStateProp', { prop: 'fvtFocusedObject', value});
    },
    setPerturbedSynodicOrbitList(context, value) {
        return context.commit('setStateProp', { prop: 'perturbedSynodicOrbitList', value });
    },
    setSynodicActiveObject(context, value) {
        return context.commit('setStateProp', { prop: 'synodicActiveObject', value });
    },
    setDetectionPolarPoints(context, value) {
        return context.commit('setStateProp', { prop: 'detectionPolarPoints', value });
    },
    setSynodicSpeed(context, value) {
        return context.commit('setStateProp', { prop: 'synodicSpeed', value });
    },
    setImpactorsList(context, value) {
        return context.commit('setStateProp', { prop: 'impactorsList', value });
    },
};

export let common = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};

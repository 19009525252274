import * as Spacekit from 'spacekit.js'
import PopupService from '@/services/popup.service'
import VisualisationService from '@/services/visualisation.service'
import HUDService from '@/services/hud.service'
import Keys from '@/constants/keys'
import * as THREE from 'three'
import UtilsService from '@/services/utils.service'
import SynodicCalculationService from '@/services/synodic-calculation.service'
import MemoryService from '@/services/memory.service'
import TimeFrameService from '@/services/timeframe.service'
import moment from "moment"
import Config from "@/constants/config"

const SpacekitService = {
    COLOR_PERTURBED: 0xffc1c1,
    COLOR_PERTURBED_SELECTED: 0xFF6363,
    COLOR_KEPLERIAN: 0x9fcef1,
    COLOR_KEPLERIAN_SELECTED: 0x0F84DD,
    COLOR_SUN: 0xFFF000,
    COLOR_MOON: 0x666666,
    COLOR_CLOSEST_POINT: 0xB50000,
    COLOR_ORBIT_RANGE: 0xFDDA0D,
    COLOR_SYNODIC_ORBIT: 0x5998D4,
    VIZ_UNITS: 10000,
    ORBIT_TYPE: {
        UNKNOWN: 0,
        PARABOLIC: 1,
        HYPERBOLIC: 2,
        ELLIPTICAL: 3,
        TABLE: 4
    },
    AU: 149597871,
    USER_DEFINED_OBJECT_DEFAULT_DIAMETER: 250,
    USER_DEFINED_OBJECT_FVT_DEFAULT_DIAMETER: 20,
    USER_DEFINED_OBJECT_DISTANCE_IMPACT_LIMIT: 300000,

    buildSimulation(tool) {
        if (document.querySelector('.vt__simulation')){
            return new Spacekit.Simulation(document.querySelector('.vt__simulation'), {
                basePath: '/static/spacekit',
                particleTextureUrl: '/static/textures/particle.png',
                maxNumParticles: 2**18,
                unitsPerAu: tool === 'fvt' ? SpacekitService.VIZ_UNITS : 1,
                camera: {
                    enableDrift: false,
                },
            });
    }else {
        return;
    }
    },

    buildBackgroundObjects(tool) {
        const vt = window['vt'];
        const camera = vt.getViewer().cameraControls;
        const scene = vt.getScene();
        const settings = VisualisationService.getSettings();
        const backColor = settings.objectsSettings.background.color;

        scene.background = new THREE.Color(backColor);
        scene.background.set( backColor );

        if (settings.objectsSettings.dsos.value && tool !== 'sovt') {
            vt.stars = vt.createStars();
        }

        if (tool === 'fvt'){
            setTimeout(() => {
                vt.stars._stars.rotation.x = 23.5 * Math.PI / 180;
            }, 500);
        }

        if (settings.objectsSettings.milkyWayBackground.value && tool !== 'sovt') {
            //vt.skybox = vt.createSkybox(Spacekit.SkyboxPresets.NASA_TYCHO); - old background
            
            vt.skybox = vt.createSkybox({
                //https://www.esa.int/var/esa/storage/images/esa_multimedia/images/2020/12/the_colour_of_the_sky_from_gaia_s_early_data_release_32/22358131-1-eng-GB/The_colour_of_the_sky_from_Gaia_s_Early_Data_Release_3_pillars.png
                textureUrl: '/static/textures/MilkyWay.png',
            });
            
            if (tool === 'fvt')
                vt.skybox.mesh.rotation.x = (23.5 + 7.8) * Math.PI / 180;
            else 
                vt.skybox.mesh.rotation.x = 7.8 * Math.PI / 180;

            vt.skybox.mesh.rotation.y = 29.55 * Math.PI / 180;
            vt.skybox.mesh.rotation.z = 85.55 * Math.PI / 180;
        }

        let size = 80;
        let divisions = 80;
        if (tool === 'ovt') {
            SpacekitService.toggleEarthName(true);
        }
        if (tool === 'fvt') {

            const activeObject = VisualisationService.getOrbitActiveObject();
            if (activeObject) {
                let name = activeObject._options.name;
                if (name.includes(" ")) {
                    name = name.split(" ")[0];
                }                
                //VisualisationService.checkObject(name, false);
            }
    
            size = 120;
            divisions = 18;
        }
        const gridHelper = new THREE.GridHelper(size, divisions, 0x666666, 0x333333);
        const axesHelper = new THREE.AxesHelper(2);

        const axesColors = axesHelper.geometry.attributes.color;
        axesColors.setXYZ(0, 1, 0, 0);
        axesColors.setXYZ(1, 1, 0, 0);
        axesColors.setXYZ(2, 0, 1, 0);
        axesColors.setXYZ(3, 0, 1, 0);
        axesColors.setXYZ(4, 0, 0.4, 1);
        axesColors.setXYZ(5, 0, 0.4, 1);

        gridHelper.geometry.rotateX(Math.PI / 2);
        if (!settings.objectsSettings.horizontalGrid.value) {
            gridHelper.visible = false;
        }
        if (!settings.objectsSettings.axisGuide.value) {
            axesHelper.visible = false;
        }
        scene.add(gridHelper);
        scene.add(axesHelper);

        vt.flybyScene = [];
        vt.planets = [];

        if (tool === 'ovt') {
            vt.createObject('sun', Spacekit.SpaceObjectPresets.SUN);

            const mercury = vt.createSphere('mercury', {...Spacekit.SpaceObjectPresets.MERCURY, ...{name: 'Mercury', textureUrl: '/static/textures/detailed/mercury.jpg', radius: 0.0024394, rotation: { enable: true, period: 58.646, lambdaDeg: 252.2509,} }});
            const venus = vt.createSphere('venus', {...Spacekit.SpaceObjectPresets.VENUS, ...{name: 'Venus', textureUrl: '/static/textures/detailed/venus.jpg', radius: 0.0060518, rotation: { enable: true, period: -243.018, lambdaDeg: 181.9798,} }});
            const earth = vt.createSphere('earth', {...Spacekit.SpaceObjectPresets.EARTH, ...{name: 'Earth', textureUrl: '/static/textures/detailed/earth.jpg', radius: 0.0063710084, rotation: { enable: true, period: 0.9972696322627929, lambdaDeg: 100.4664,} }});
            const mars = vt.createSphere('mars', {...Spacekit.SpaceObjectPresets.MARS, ...{name: 'Mars', textureUrl: '/static/textures/detailed/mars.jpg', radius: 0.0033895, rotation: { enable: true, period: 1.026, lambdaDeg: 355.4329,} }});
            const jupiter = vt.createSphere('jupiter', {...Spacekit.SpaceObjectPresets.JUPITER, ...{name: 'Jupiter', textureUrl: '/static/textures/detailed/jupiter.jpg', radius: 0.069911, rotation: { enable: true, period: 0.41354, lambdaDeg: 34.3515,} }});
            const saturn = vt.createSphere('saturn', {...Spacekit.SpaceObjectPresets.SATURN, ...{name: 'Saturn', textureUrl: '/static/textures/detailed/saturn.jpg', radius: 0.058232, rotation: { enable: true, period: 0.444, lambdaDeg: 50.0774,} }});
            const uranus = vt.createSphere('uranus', {...Spacekit.SpaceObjectPresets.URANUS, ...{name: 'Uranus', textureUrl: '/static/textures/detailed/uranus.jpg', radius: 0.025362, rotation: { enable: true, period: -0.718, lambdaDeg: 314.055,} }});
            const neptune = vt.createSphere('neptune', {...Spacekit.SpaceObjectPresets.NEPTUNE, ...{name: 'Neptune', textureUrl: '/static/textures/detailed/neptune.jpg', radius: 0.024622, rotation: { enable: true, period: 0.671, lambdaDeg: 304.3486,} }});
            
            vt.planets = [mercury, venus, earth, mars, jupiter, saturn, uranus, neptune];
            for (const planet of vt.planets) {
                planet._object3js.name = planet._id.toString();
                if (planet._id === 'earth') {
                    planet._options.labelLayer = 1;
                    planet._objectIsRotatable = false;
                    planet._options.rotation.enable = false;
                    HUDService.createLabel(planet, UtilsService.hexColor(settings.objectsSettings.earthName.color));
                } else {
                    planet._options.labelLayer = 0;
                    HUDService.createLabel(planet,UtilsService.hexColor(settings.objectsSettings.planetsNames.color));
                }                
            }
        } else if (tool !== 'sovt') {
            vt.createSphere('dummy', {...Spacekit.SpaceObjectPresets.SUN, ...{textureUrl: '/', radius: 0, visible: false, ecliptic: {displayLines: false}}});
        }

        if (tool === 'sovt') {
            vt.createObject('sun', Spacekit.SpaceObjectPresets.SUN);
            SpacekitService.enableSovtToolFeatures();
        }

        camera.enableZoom = false;
        camera.rotateSpeed = 0.25;
        camera.dampingFactor = 0.33;

        vt.onTick = () => {
            if (!vt.isPaused) {
                const now = vt.getDate();
                VisualisationService.setNow(now);
            }
        };
    },

    checkCameraFrustum(camera, position) {
        const frustum = new THREE.Frustum();
        const matrix = new THREE.Matrix4().multiplyMatrices(camera.projectionMatrix, camera.matrixWorldInverse);
        frustum.setFromProjectionMatrix(matrix);
        return frustum.containsPoint(position);
    },

    mapEphemeridesTableForFlyby(jds, positions) {
        const dataObject = [];
        for (const [index, jd] of jds.entries()) {
            if (positions[index]) {
                dataObject.push(
                    [jd*1 + 2400000.5, positions[index][0] * SpacekitService.AU, positions[index][1] * SpacekitService.AU, positions[index][2] * SpacekitService.AU, 0, 0, 0]
                )
            }
        }
        const ephemeridesTable = {
            data: dataObject,
            distanceUnits: 'km',
            timeUnits: 'day',
        }
        return new Spacekit.EphemerisTable(ephemeridesTable)
    },

    mapEphemeridesTable(data) {
        const dataObject = data.map(e => [e.epoch*1 + 2400000.5, e.xa * SpacekitService.AU, e.ya * SpacekitService.AU, e.za * SpacekitService.AU, 0, 0, 0]);
        const ephemeridesTable = {
            data: dataObject,
            distanceUnits: 'km',
            timeUnits: 'day',
        }

        const uncTable = [];
        const closeApproachTable = [];
        for (const datastamp of data) {
            if (datastamp.unc) {
                uncTable.push(datastamp.epoch);
            }
            if (datastamp.epoch % 1 !== 0) {
                const epoch = parseInt(datastamp.epoch);
                if (closeApproachTable.indexOf(epoch) === -1) {
                    closeApproachTable.push(epoch);
                }
            }
        }

        return {
            ephemObject: new Spacekit.EphemerisTable(ephemeridesTable),
            uncTable,
            closeApproachTable,
        };
    },

    mapEphemerides(data) {
        return new Spacekit.Ephem({
            a: data.a || 0,
            e: data.e || 0,
            i: data.i || data.incl || null,
            om: data.om || data.node || null,
            w: data.w || data.peri || null,
            ma: data.ma || data.m || null,
            q: data.q || null,
            epoch: data.epoch < 2400000.5 ? data.epoch*1 + 2400000.5 : data.epoch || null,
            tp: data.tp || null,
        }, 'deg');
    },

    addSmallObjects(type, phrase, smallObjects, color) {
        const particleSize = VisualisationService.getSettings().particleSize;
        const selects = VisualisationService.getOrbitVisualisationSelects();
        let optionFromSelects = [];
        if (selects[type]) {
            optionFromSelects =  selects[type].filter(e => e.option == phrase);
        }

        if (!optionFromSelects.length)
        {            
            return;
        }

        color = Number('0x' + optionFromSelects[0].color);        

        const vt = window['vt'];
        for (const [index, object] of smallObjects.entries()) {
            const ephem = SpacekitService.mapEphemerides(object);
            const name = `${type}_${phrase}_${index}`;
            try {
                const smallObject = vt.createObject(name, {
                    hideOrbit: true,
                    particleSize,
                    theme: {
                        color,
                    },
                    ephem,
                    name,
                    mag: object.mag || object.h || null,
                    diam: object.diam || object.size,
                });
                vt.smallObjects.push(smallObject);
            } catch (error) {
                PopupService.show({
                    component: 'PopupInfo',
                    type: 'error',
                    message: error,
                });
                return;
            }
        }
        SpacekitService.updateDisplayLimiters();
        if (vt.subscribedObjects.KeplerParticles__0 && vt.subscribedObjects.KeplerParticles__0.get3jsObjects()[0]) {
            vt.subscribedObjects.KeplerParticles__0.get3jsObjects()[0].frustumCulled = false;
        }  
    },

    getSmallObjectsGroup(group, option = null) {
        if ( group === 'risk_list') {
            group = 'risk';
        }

        let groupId = group;
        if (option) {
            groupId = group + '_' + option;
        }

        const vt = window['vt'];
        const selectedGroup = vt.smallObjects.filter(e => e._id && e._id.indexOf(groupId) === 0);

        return selectedGroup;
    },

    removeSmallObjects(group, option = null) {
        const vt = window['vt'];
        const groupObjects = SpacekitService.getSmallObjectsGroup(group, option);

        for (const groupObject of groupObjects) {
            vt.removeObject(groupObject);
            SpacekitService.hideParticle(groupObject);
            const indexToRemove = vt.smallObjects.indexOf(groupObject);
            vt.smallObjects.splice(indexToRemove, 1);
        }
    },
    
    getObject(designator) {
        const vt = window['vt'];
        const isPlanet = Config.planetsSelectList.some(planet => planet.toLowerCase() === designator);
        return isPlanet ? vt.planets.filter(planet => (planet._id === designator))[0] : vt.spaceObjects.filter(e => (e._id == designator || e._id == designator+'_perturbed'))[0];
    },

    getAllObjects() {
        const vt = window['vt'];
        return vt.spaceObjects;
    },

    spaceObjectOptions() {
        return {
            textureUrl: '/static/textures/marker-square.png',
            radius: 0.0025,
            hideOrbit: false,
            theme: {
                color: 0xAAAAAA,
                orbitColor: 0xAAAAAA,
            },
            ecliptic: {
                displayLines: true,
                lineColor: 0x333333,
            },
        }
    },

    toggleGridSize(mainBodyId) {
        const mainBodyType = mainBodyId ? VisualisationService.getMainBodyParameters(mainBodyId).type : null;
        const vt = window['vt'];
        const scene = vt.getScene();
        const settings = VisualisationService.getSettings();

        let divisions = 18;
        if (mainBodyType && mainBodyType === 'rocky') {
            divisions = 36;
        }

        const oldGrid = scene.getObjectByProperty('type', 'GridHelper');
        if (oldGrid) {
            scene.remove(oldGrid);
        }

        const gridHelper = new THREE.GridHelper(120, divisions, 0x666666, 0x333333);
        gridHelper.geometry.rotateX(Math.PI / 2);
        if (!settings.objectsSettings.horizontalGrid.value) {
            gridHelper.visible = false;
        } 
        scene.add(gridHelper);
    },

    addPerturbedOrbit(ephemerides, designator) {
        //const object = SpacekitService.getObject(designator);
        const objects = VisualisationService.getKeplerianData();
        let object;

        for (const obj of objects) {
            if (obj.catalogueNumber === designator.toString()){
                object = obj;
            }
        }

        if (!object) return;

        const settings = VisualisationService.getSettings();                        
        const leadDurationYears = settings.objectsSettings.leadDurationYears.value;
        const trailDurationYears = settings.objectsSettings.trailDurationYears.value;        
        const numberSamplePoints = (leadDurationYears + trailDurationYears) * settings.points_density; 
        const {ephemObject, uncTable, closeApproachTable} = SpacekitService.mapEphemeridesTable(ephemerides);
        const diam = object.diam ? object.diam : UtilsService.estimateDiameter(object.mag);
        const spaceObjectOptions = {...SpacekitService.spaceObjectOptions(), ...{/* eslint-disable-line */
            /*
            name: object ? object._options.name : '',
            diam: object ? object._options.diam : '',
            mag: object ? object._options.mag : '',
            keplerianOrbitPeriod: object ? object._orbit.ephem.attrs.period : '',
            */

            name: object ? object.designator : '',
            diam,
            mag: object ? object.mag : '',
            keplerianOrbitPeriod: object ?  object.orbitPeriod : '',
            

            labelLayer: 2,
            ephemTable: ephemObject,            
            orbitPathSettings: {
                numberSamplePoints,
                leadDurationYears,
                trailDurationYears,
            },
            uncTable,
            closeApproachTable,
        }};
        SpacekitService.removeObject(object.catalogueNumber);
        const vt = window['vt'];
        const spaceObject = vt.createSphere(object.catalogueNumber+'_perturbed', spaceObjectOptions);
        spaceObject._object3js.name = object.catalogueNumber.toString();
        vt.spaceObjects.push(spaceObject);
        HUDService.createLabel(spaceObject,  null, null,['center', 'top']);
        SpacekitService.turnOffOrbit(spaceObject); 

        let color = UtilsService.hexColor(settings.objectsSettings.keplerianOrbits.color);
        if (spaceObject._useEphemTable) {                                                
            color = UtilsService.hexColor(settings.objectsSettings.perturbedOrbits.color);
        }
        const currentSelectedName = VisualisationService.getSelectedObjectName();
        if (currentSelectedName === object.catalogueNumber.toString())
            SpacekitService.turnOnOrbit(spaceObject, color);
    },

    addSyntheticPerturbedOrbit(ephemerides, syntheticObjectData) {
        const settings = VisualisationService.getSettings();                        
        const leadDurationYears = settings.objectsSettings.leadDurationYears.value;
        const trailDurationYears = settings.objectsSettings.trailDurationYears.value;        
        const numberSamplePoints = (leadDurationYears + trailDurationYears) * settings.points_density; 
        const {ephemObject, uncTable, closeApproachTable} = SpacekitService.mapEphemeridesTable(ephemerides);
        const diam = syntheticObjectData.diam ? syntheticObjectData.diam : UtilsService.estimateDiameter(syntheticObjectData.mag);
        const spaceObjectOptions = {...SpacekitService.spaceObjectOptions(), ...{
            name: syntheticObjectData.objectName || '',
            diam,
            mag: syntheticObjectData.mag || '',
            keplerianOrbitPeriod: syntheticObjectData.orbitPeriod || '',
            labelLayer: 2,
            ephemTable: ephemObject,            
            orbitPathSettings: {
                numberSamplePoints,
                leadDurationYears,
                trailDurationYears,
            },
            uncTable,
            closeApproachTable,
        }};
        const vt = window['vt'];
        const spaceObject = vt.createSphere(syntheticObjectData.designator+'_perturbed', spaceObjectOptions);
        spaceObject._object3js.name = syntheticObjectData.designator.toString();
        vt.spaceObjects.push(spaceObject);
        SpacekitService.turnOffOrbit(spaceObject);
        let color = UtilsService.hexColor(settings.objectsSettings.keplerianOrbits.color);
        if (spaceObject._useEphemTable) {                                                
            color = UtilsService.hexColor(settings.objectsSettings.perturbedOrbits.color);
        }
        const currentSelectedName = VisualisationService.getSelectedObjectName();
        if (currentSelectedName === syntheticObjectData.designator.toString())
            SpacekitService.turnOnOrbit(spaceObject, color);

        const earthObject = vt.planets.filter(e => e._id === 'earth')[0];
        const impactDate = this.checkForSyntheticObjectImpact(spaceObject, earthObject, ephemObject.data[ephemObject.data.length - 1][0]);
        spaceObject.impactDate = impactDate;
        if (impactDate) {
            PopupService.show({
                component: "PopupInfo",
                type: "warning",
                message: Keys.t.trajectoryImpact
                    .replace("{objectName}", syntheticObjectData.objectName)
                    .replace("{impactDate}", impactDate),
            });
        }  

        let isObjectInvisible = false;

        if (impactDate) {
            const simulationDate = moment.utc(TimeFrameService.getSimulationTime()).format("YYYY-MM-DD");
            const simulationTimestamp = new Date(simulationDate).getTime();
            const impactTimestamp = new Date(impactDate).getTime();
            isObjectInvisible = simulationTimestamp > impactTimestamp;
        }

        isObjectInvisible && (spaceObject._object3js.visible = false);

        !isObjectInvisible && HUDService.createLabel(
            spaceObject,
            UtilsService.hexColor(settings.objectsSettings.objectNames.color),
            settings.objectsSettings.objectNamesSize.value
        )
    },

    updatePerturbedOrbit() {                     
        const vt = window['vt'];
        const perturbedObjects = vt.spaceObjects.filter(o => o._id.includes('_perturbed'));   
        const perturbedob = VisualisationService.getIsPerturbed();
        const checked = VisualisationService.getIsChecked();

        for (const spaceObject of perturbedObjects) { 
            const designator = spaceObject._id.replace("_perturbed","");
            if (checked.indexOf(designator) > -1 && perturbedob.includes(designator)) {
                VisualisationService.addOrbitData(designator, true);
            }
        } 
        vt.staticForcedUpdate();              
    },

    checkForSyntheticObjectImpact(spaceObject, earthObject, lastDate) {
        const end = TimeFrameService.getTimeFrame().converted.end;
        const xyzObject = spaceObject.getPosition(lastDate);
        const xyzEarth = earthObject.getPosition(lastDate);
        const distanceToEarth = VisualisationService.distanceToBody(xyzObject, xyzEarth, 6) * SpacekitService.AU;
        const impactDetected = lastDate < end && distanceToEarth < SpacekitService.USER_DEFINED_OBJECT_DISTANCE_IMPACT_LIMIT;
        return impactDetected ? UtilsService.dateToStringTechnical(UtilsService.julianToDateObject(lastDate)) : null;
    },

    createObject(object) {
        const vt = window['vt'];
        const ephem = SpacekitService.mapEphemerides(object);
        const diam = object.diam ? object.diam : UtilsService.estimateDiameter(object.mag);
        var spaceObjectOptions;
        if ((object.object === "asteroid") && (object.e >= 0.9 )) {
            spaceObjectOptions = {...SpacekitService.spaceObjectOptions(), ...{
                name: object.objectName || object.designator,
                diam,
                mag: object.mag,
                labelLayer: 2,
                ephem,
                orbitPathSettings: {
                    numberSamplePoints: 2 * object.orbitPeriod,      
                    trailDurationYears: 2 * object.orbitPeriod / 365.25,      
                    leadDurationYears: 2 * object.orbitPeriod / 365.25
                },
            }};
        } else { // commets or excentricity < 0.9
            spaceObjectOptions = {...SpacekitService.spaceObjectOptions(), ...{
                name: object.objectName || object.designator,
                diam,
                mag: object.mag,
                labelLayer: 2,
                ephem,
                orbitPathSettings: {
                    numberSamplePoints: 10 * VisualisationService.getSettings().points_density,                                    
                },
            }};
        }        

        const spaceObject = vt.createSphere(object.catalogueNumber+'', spaceObjectOptions);
        spaceObject._object3js.name = object.catalogueNumber.toString();

        if (ephem.attrs.e > 0.9 && ephem.attrs.period){
            vt.getScene().remove(spaceObject._orbitPath);
            spaceObject.update(vt.getJd(), true); 
            spaceObject._orbit.orbitType = 3;
            spaceObject._orbitPath = null;

            const orbitPoints = SpacekitService.getEllipsePoints(spaceObject._orbit);
            spaceObject._orbit.orbitPoints = orbitPoints;
            const cachedOrb = spaceObject._orbit.generateAndCacheOrbitShape(orbitPoints);

            spaceObject._orbitPath = cachedOrb;
            vt.getScene().add(cachedOrb);
        }
        vt.spaceObjects.push(spaceObject);

        HUDService.createLabel(spaceObject,  null, null, ['center', 'top']);
        SpacekitService.turnOffOrbit(spaceObject);
    },

    getEllipsePoints(spaceObjectOrbit){
        const eph = spaceObjectOrbit.ephem;
    
        const a = eph.get('a');
        const ecc = eph.get('e');
    
        const twoPi = Math.PI * 2;
        const ptsNumber = spaceObjectOrbit.options.orbitPathSettings.numberSamplePoints;
        //const step = twoPi / 90;
        const step = twoPi / ptsNumber;
        const pts = [];
        for (let E = 0; E < twoPi; E += step) {
          const v = 2 * Math.atan(Math.sqrt((1 + ecc) / (1 - ecc)) * Math.tan(E / 2));
          const r = (a * (1 - ecc * ecc)) / (1 + ecc * Math.cos(v));
          const pos = spaceObjectOrbit.vectorToHeliocentric(v, r);
    
          if (isNaN(pos[0]) || isNaN(pos[1]) || isNaN(pos[2])) {
            console.error(
              'NaN position value - you may have bad or incomplete data in the following ephemeris:',
            );
            console.error(eph);
          }
          pts.push(new THREE.Vector3(pos[0], pos[1], pos[2]));
        }
        pts.push(pts[0]);
        return pts;
    },

    removeObject(designator) {
        const spaceObject = SpacekitService.getObject(designator);
        if (spaceObject) {
            const vt = window['vt'];
            HUDService.removeLabel(spaceObject);
            vt.removeObject(spaceObject);
            const indexToRemove = vt.spaceObjects.indexOf(spaceObject);
            vt.spaceObjects.splice(indexToRemove, 1);
        }
    },

    getMinCameraDistance(radius) {
        return radius * SpacekitService.AU / 10000;
    },

    setMinCameraDistance(units) {
        const vt = window['vt'];
        VisualisationService.setMinCameraDistance(units);
        vt.getViewer().cameraControls.minDistance = units;
    },

    focusOnObject(designator, flyby = false, setZoom = false) {
        const tool = VisualisationService.getTool();
            if (tool !== 'opt' && tool !== 'scd'){
            const vt = window['vt'];
            const spaceObject = flyby ? vt.subscribedObjects[designator] : SpacekitService.getObject(designator);
            const params = VisualisationService.getMainBodyParameters(designator);
            const loadedState = {...MemoryService.getToolState()};
            let zoom;

            if (loadedState.tool === 'ovt')
                zoom = loadedState.activeZoomOVT;
            else if (loadedState.tool === 'fvt')
                zoom = loadedState.activeZoomFVT;
        
            if (flyby) {
                SpacekitService.setMinCameraDistance(0.15);
            }else{
                SpacekitService.setMinCameraDistance(0.004);
            }
            if (params && params.radius) {
                SpacekitService.setMinCameraDistance(SpacekitService.getMinCameraDistance(params.radius) / 10);
            }
            if (spaceObject){
                vt.getViewer().followObject(spaceObject, [-0.01, -0.01, 0.01]);
            }
            if (flyby) {
                SpacekitService.setZoom(designator && designator !== 'sun' ? 1 : 130);
            } 
            if (zoom && setZoom) {
                SpacekitService.setZoom(zoom);
            }
            setTimeout(() => {
                vt.getViewer().cameraControls.enablePan = true;
                vt.staticForcedUpdate();
            }, 100);
        }
    },

    focusOnCenter(flyby = false, setZoom = false) {
        const tool = VisualisationService.getTool();
        if (tool !== 'opt' && tool !== 'scd'){
            const vt = window['vt'];
            if (!vt || !vt.getViewer()) {
                return;
            }
            const center = flyby ? vt.flybyScene.filter(e => e._id === 'mainbody')[0] :vt.subscribedObjects.sun;
            if (center){
                vt.getViewer().followObject(center, [-0.01, -0.01, 0.01]);
            }
            const loadedState = {...MemoryService.getToolState()};

            let zoom;

            if (loadedState.tool === 'ovt')
                zoom = loadedState.activeZoomOVT;
            else if (loadedState.tool === 'fvt')
                zoom = loadedState.activeZoomFVT;

            if (flyby) {
                SpacekitService.setZoom(10);
                const params = VisualisationService.getMainBodyParameters(VisualisationService.getSelectedObjectName());
                if (params){
                    SpacekitService.setMinCameraDistance(params.radius * SpacekitService.AU / 10000);
                }
            } 

            if (zoom && setZoom) {
                SpacekitService.setZoom(zoom);
            }
            setTimeout(() => {
                vt.getViewer().stopFollowingObject();
                vt.staticForcedUpdate();
            }, 100);
        }
    },

    focusOnSovtObject(focusedObject) {
        const vt = window["vt"];
        const isEarthSelected =
            focusedObject ===
            SynodicCalculationService.TRANSFORMED_EARTH_OBJECT_NAME;
        const isPlanetSelected = Config.planetsSelectList.map(planet => `${planet.toLowerCase()}${SynodicCalculationService.TRANSFORMED_SPACE_OBJECT_SUFFIX}`).some(planet => planet === focusedObject);
        const isEarthOnScale = VisualisationService.getSettings().objectsSettings.earthOnScale.value;
        const earthZoomFactor = isEarthOnScale ? SynodicCalculationService.EARTH_ON_SCALE_FACTOR : 1;
        const asteroidZoomFactor = isEarthOnScale ? SynodicCalculationService.ASTEROID_ZOOM_FACTOR : 1;
        const objectsIds = [
            `${focusedObject}${SynodicCalculationService.TRANSFORMED_SPACE_OBJECT_SUFFIX}`,
            `${focusedObject}${SynodicCalculationService.TRANSFORMED_SPACE_OBJECT_SUFFIX_PERTURBED}`,
        ];
        const spaceObject = isPlanetSelected
            ? vt.planets.find((planet) => planet._id === focusedObject)
            : vt.spaceObjects.find((object) => objectsIds.includes(object._id));
        if (!spaceObject) {
            return;
        }
        if (isEarthSelected) {
            SpacekitService.setMinCameraDistance(((6378.1366 / 100000) * earthZoomFactor));
        }
        if (isPlanetSelected && !isEarthSelected) {
            const planetParams = VisualisationService.getMainBodyParameters(focusedObject.replace(SynodicCalculationService.TRANSFORMED_SPACE_OBJECT_SUFFIX, ''));
            SpacekitService.setMinCameraDistance(planetParams && planetParams.radius ? (planetParams.radius * SpacekitService.AU / 100000) : 0.004);
        }
        if (!isPlanetSelected && !isEarthSelected) {
            SpacekitService.setMinCameraDistance(0.004 * asteroidZoomFactor);
        }
        setTimeout(() => {
            vt.getViewer().followObject(spaceObject, [0, 0, 0]);
            vt.getViewer().cameraControls.enablePan = true;
            vt.staticForcedUpdate();
        }, 100);
    },

    turnOffOrbit(spaceObject) {
        let color, colorLighter;
        const settings = VisualisationService.getSettings();
        if(spaceObject._useEphemTable) { //perturbed ligther            
            colorLighter = UtilsService.applyOpacityToHexColor(settings.objectsSettings.perturbedOrbits.color, 0.4);                          
        }else{ //keplerian ligther            
            colorLighter = UtilsService.applyOpacityToHexColor(settings.objectsSettings.keplerianOrbits.color, 0.4);                        
        }
        color = UtilsService.hexColor(colorLighter);        
        spaceObject.getOrbit().options.color = color;
        const orbitColor = new THREE.Color(color);
        spaceObject.getOrbit().setHexColor(orbitColor);
        spaceObject._options.ecliptic.displayLines = false;
        spaceObject._simulation.getScene().remove(spaceObject._eclipticLines);
    },

    turnOnOrbit(spaceObject, color) {
        const settings = VisualisationService.getSettings();
        spaceObject.getOrbit().options.color = Number(color);
        spaceObject.getOrbit().setHexColor(color);        
        spaceObject._options.ecliptic.displayLines = settings.objectsSettings.horizontalPlane.value;

        if (settings.objectsSettings.horizontalPlane.value) {
            spaceObject._simulation.getScene().remove(spaceObject._eclipticLines);
            spaceObject._eclipticLines = this.getLinesToEcliptic(spaceObject);
            spaceObject._simulation.getScene().add(spaceObject._eclipticLines);
        } else {
            spaceObject._options.ecliptic.displayLines = false;
            spaceObject._simulation.getScene().remove(spaceObject._eclipticLines);
        }
    },

    turnOffSynodicOrbit(spaceObject) {
        const objectsSettings = VisualisationService.getSettings().objectsSettings;
        const keplerianOrbitColor = UtilsService.hexColor(UtilsService.applyOpacityToHexColor(objectsSettings.keplerianOrbits.color, 0.4));
        const perturbedOrbitColor = UtilsService.hexColor(UtilsService.applyOpacityToHexColor(objectsSettings.perturbedOrbits.color, 0.4));
        const color = spaceObject._id.toString().endsWith(SynodicCalculationService.TRANSFORMED_SPACE_OBJECT_SUFFIX) 
                ? keplerianOrbitColor : perturbedOrbitColor;
        spaceObject.getOrbit().options.color = color;
        spaceObject.getOrbit().setHexColor(color);
        spaceObject._options.ecliptic.displayLines = false;
        spaceObject._simulation.getScene().remove(spaceObject._eclipticLines);
    },

    highlightObject(designator, activate) {
        const spaceObjects = SpacekitService.getAllObjects();
        const settings = VisualisationService.getSettings();   
        const labelsVisibility = settings.objectsSettings.objectNames.value;

        if (!activate) {
            const activeObject = VisualisationService.getOrbitActiveObject();
            SpacekitService.turnOffOrbit(activeObject)
            VisualisationService.setOrbitActiveObject(null);
        } else {
            for (const spaceObject of spaceObjects) {
                if (spaceObject._id == designator || spaceObject._id == designator+'_perturbed') {                    
                    let color = UtilsService.hexColor(settings.objectsSettings.keplerianOrbits.color);
                    if (spaceObject._useEphemTable) {                                                
                        color = UtilsService.hexColor(settings.objectsSettings.perturbedOrbits.color);
                    }
                    SpacekitService.turnOnOrbit(spaceObject, color);
                    VisualisationService.setOrbitActiveObject(spaceObject);
                    SpacekitService.toggleObjectsNames(labelsVisibility);
                    SpacekitService.toggleEarthName(true);
                } else {
                    SpacekitService.turnOffOrbit(spaceObject);
                }
            }
        }
    },

    handleSynodicOrbitHiglight() {
        const spaceObjects = SpacekitService.getAllObjects();
        const selectedSynodicSpaceObject = VisualisationService.getSelectedSynodicObjectName();
        const objectIdRegex = new RegExp("^(.*?)" + SynodicCalculationService.TRANSFORMED_SPACE_OBJECT_SUFFIX);
        const objectsSettings = VisualisationService.getSettings().objectsSettings;
        const activeKeplerianOrbitColor = objectsSettings.keplerianOrbits.color;
        const activePerturbedOrbitColor = objectsSettings.perturbedOrbits.color;
        spaceObjects.forEach(object => {
            const matchObjectId = object._id.match(objectIdRegex);
            const tranformedObjectId = matchObjectId ? matchObjectId[1] : object._id;
            if (tranformedObjectId === selectedSynodicSpaceObject) {
                let color = object._id.toString().endsWith(SynodicCalculationService.TRANSFORMED_SPACE_OBJECT_SUFFIX) 
                    ? activeKeplerianOrbitColor : activePerturbedOrbitColor;
                SpacekitService.turnOnOrbit(object, color);
            } else {
                SpacekitService.turnOffSynodicOrbit(object);
            }
        })
    },

    play() {
        const vt = window['vt'];
        const tool = VisualisationService.getTool();
        const settings = VisualisationService.getSettings();
        const showArrows = settings.objectsSettings.guidingArrows.value;
        SpacekitService.toggleGuidingArrows(showArrows);
        if (tool === 'ovt') {
            const rotationDirection = this.getSpeed() >= 0 ? 1 : -1; // Determine the rotation direction based on the sign of speed
            const earthSphere = vt.subscribedObjects.earth;
            earthSphere._objectIsRotatable = true;
            earthSphere._options.rotation.enable = true;
            earthSphere._options.rotation.speed = rotationDirection;
        }

        //const currentAsteroid = vt.subscribedObjects.asteroid;
   
        //if (currentAsteroid) {
            //currentAsteroid.startRotation();
        //}

        vt.start();
    },

    pause() {
        const vt = window['vt'];

        const tool = VisualisationService.getTool();
        if (tool === 'ovt'  || tool === 'sovt') {
            const earthSphere = tool === 'ovt' ? vt.subscribedObjects.earth : vt.subscribedObjects.earth_transformed;
            if (earthSphere) {
                earthSphere._objectIsRotatable = false;
                earthSphere._options.rotation.enable = false;
                earthSphere.stopRotation();
            }
        }

        const currentAsteroid = vt.subscribedObjects.asteroid;
        if (currentAsteroid) {
            currentAsteroid.stopRotation();
        }
        vt.stop();
    },

    setDate(date) {
        const tool = VisualisationService.getTool();
        if (tool === 'ovt' || tool === 'fvt' || tool === 'sovt') {
            VisualisationService.setSimulationTime(date);
        }
        const vt = window['vt'];
        vt.setDate(date);
        VisualisationService.updateDistances(date);
    },

    setSpeed(speed) {
        const vt = window['vt'];
        vt.setJdPerSecond(speed);
    },

    getSpeed() {
        const vt = window['vt'];
        return vt.jdPerSecond;
    },    
    toggleHorizontalPlane(boolean) {
        const tool = VisualisationService.getTool();
        let activeObject = VisualisationService.getOrbitActiveObject();
        if (tool === 'sovt') {
            const selectedSynodicObject = VisualisationService.getSelectedSynodicObjectName();
            const visibleSynodicObjectsList = SynodicCalculationService.getVisibleRealSynodicObjectsList();
            const objectIdRegex = new RegExp("^(.*?)" + SynodicCalculationService.TRANSFORMED_SPACE_OBJECT_SUFFIX);
            activeObject = visibleSynodicObjectsList.includes(selectedSynodicObject) ?
                SpacekitService.getAllObjects().find(object => {
                    const matchObjectId = object._id.match(objectIdRegex);
                    const tranformedObjectId = matchObjectId ? matchObjectId[1] : object._id;
                    return tranformedObjectId === selectedSynodicObject
                }) : null;
        }
        if (activeObject && (tool === 'ovt' || tool === 'sovt')) {
            activeObject._eclipticLines.visible = boolean;
            activeObject._options.ecliptic.displayLines = boolean;
            if (boolean) {
                activeObject._simulation.getScene().remove(activeObject._eclipticLines);

                if (tool === 'ovt'){
                    activeObject._eclipticLines = this.getLinesToEcliptic(activeObject);
                }
                else{
                    activeObject._eclipticLines = activeObject._orbit.getLinesToEcliptic();
                }

                activeObject._simulation.getScene().add(activeObject._eclipticLines);
            } else {
                activeObject._simulation.getScene().remove(activeObject._eclipticLines);
            }
        } else {
            const vt = window['vt'];
            for (const object of vt.flybyScene) {
                if (object._id === 'asteroid') {
                    object._eclipticLines.visible = boolean;
                    object._options.ecliptic.displayLines = boolean;
                    if (boolean) {
                        object._simulation.getScene().remove(object._eclipticLines);
                        object._eclipticLines = this.getLinesToEcliptic(object);
                        object._simulation.getScene().add(object._eclipticLines);
                    } else {
                        object._simulation.getScene().remove(object._eclipticLines);
                    }
                    return;
                }
            }
        }       
    },

    toggleObjectsNames(boolean) {
        const vt = window['vt'];
        if (boolean) {
            vt.cameraOrtho.layers.enable(2);
        } else {
            vt.cameraOrtho.layers.disable(2);
        }
    },

    toggleMagnitudeLimitLabels(boolean) {
        const vt = window['vt'];
        if (boolean) {
            vt.cameraOrtho.layers.enable(3);
        } else {
            vt.cameraOrtho.layers.disable(3);
        }
    },

    toggleEarthName(boolean) {
        const vt = window['vt'];
        if (boolean) {
            vt.cameraOrtho.layers.enable(1);
        } else {
            vt.cameraOrtho.layers.disable(1);
        }
    },

    toggleClosestApproachPoint(boolean) {
        const vt = window['vt'];
        const closestApproachPoint = vt.getScene().getObjectByName('ClosestApproachPoint');
        //const closestApproachPointLabel = vt.sceneOrtho.getObjectByName('Closest approach point');
        if (closestApproachPoint) {
            closestApproachPoint.visible = boolean;
            //closestApproachPointLabel.visible = boolean;
            if(boolean){
                HUDService.createLabel(closestApproachPoint);
            }
            else{
                HUDService.removeLabel(closestApproachPoint);
            }
        }
    },

    toggleMilkyWay(boolean) {
        const vt = window['vt'];
        if (vt.skybox && vt.skybox.get3jsObjects()[0]) {
            vt.skybox.get3jsObjects()[0].visible = boolean;
        }
    },

    toggleStars(boolean) {
        const vt = window['vt'];
        if (vt.stars && vt.stars.get3jsObjects()[0]) {
            vt.stars.get3jsObjects()[0].visible = boolean;
        }
    },

    updateBackgroundColor(color) {
        const vt = window['vt'];
        const scene = vt.getScene();
        const settings = VisualisationService.getSettings();

        scene.background = new THREE.Color(color );
        scene.background.set( color );

        settings.background = color;
        VisualisationService.setSettings(settings); 
    },

    keepPlanetTexture(){
        const vt = window['vt'];
        const tool = VisualisationService.getTool();
        const inverted = VisualisationService.getInvertColors();

        if (tool === 'ovt') {
            if (inverted) {
                for (const planet of vt.planets) {       
                    if (!planet) return;  
                    let textureUrl = planet._options.textureUrl;
                    let lowName = planet._options.name.toLowerCase();
                    let invLowName = lowName + '_neg';
                    let textureUrlInv = textureUrl.replace(lowName, invLowName);
                    planet._options.textureUrl = textureUrlInv;           

                    var textureLoader = new THREE.TextureLoader();
                    textureLoader.load(textureUrlInv, function(newTexture) {
                        planet._obj.children[0].children[0].material.map = newTexture;
                        planet._obj.children[0].children[0].material.needsUpdate = true;
                    });
                }

            } else  {
                for (const planet of vt.planets) {
                    if (!planet) return;
                    let textureUrl = planet._options.textureUrl;
                    let lowName = planet._options.name.toLowerCase();
                    let invLowName = lowName + '_neg';
                    let textureUrlInv = textureUrl.replace(invLowName, lowName);
                    planet._options.textureUrl = textureUrlInv;  
                    
                    textureLoader = new THREE.TextureLoader();
                    textureLoader.load(textureUrlInv, function(newTexture) {
                        planet._obj.children[0].children[0].material.map = newTexture;
                        planet._obj.children[0].children[0].material.needsUpdate = true;
                    });    
                }
            }
        }
        else if (tool === 'fvt') {
            const mainbody = vt.flybyScene.filter(e => e._id === 'mainbody')[0];
            const moon = vt.flybyScene.filter(e => e._id === 'moon')[0];
            const sun = vt.flybyScene.filter(e => e._id === 'sun')[0];
            const center = mainbody ? mainbody._options.name : '';

            const objects = [moon, mainbody, sun];

            if (inverted) {
                for (const object of objects) {
                    if (!object) return;
                    let textureUrl = object._options.textureUrl;
                    let lowName = object._options.name.toLowerCase();
                    let invLowName = lowName + '_neg';
                    let textureUrlInv = textureUrl.replace(lowName, invLowName);
                    object._options.textureUrl = textureUrlInv;    

                    if (center === 'Saturn') {
                        mainbody.addRings(74270.580913, 140478.924731, '/static/textures/detailed/saturn_rings_neg.png');
                    }       

                    object.init();

                    const settings = VisualisationService.getSettings();
                    const lightEffect = settings.objectsSettings.lightEffect.value;
                    SpacekitService.toggleLightEffect(lightEffect);
                    
                }
            }
            else{
                for (const object of objects) {
                    if (!object) return;
                    let textureUrl = object._options.textureUrl;
                    let lowName = object._options.name.toLowerCase();
                    let invLowName = lowName + '_neg';
                    let textureUrlInv = textureUrl.replace(invLowName, lowName);
                    object._options.textureUrl = textureUrlInv;    

                    if (center === 'saturn') {
                        mainbody.addRings(74270.580913, 140478.924731, '/static/textures/detailed/saturn_rings.png');
                    }        

                    object.init();

                    const settings = VisualisationService.getSettings();
                    const lightEffect = settings.objectsSettings.lightEffect.value;   
                    SpacekitService.toggleLightEffect(lightEffect);
                }
            }
        }
    },

    toggleLightEffect(boolean) {
        const vt = window['vt'];
        vt.getScene().traverse(object => {
            const pointLight = vt.getScene().children.filter(e => e.type === 'PointLight')[0];
            if (!pointLight) return;
            const camera = vt.camera.get3jsCamera();
            const controls = vt.camera.get3jsCameraControls();
            const material = object.material;
            const uniforms = material && material.uniforms;
            const shaderMaterial = uniforms && uniforms.lightPos;
            let sourcePosition, targetPosition;

            const propagateLightPosition = (source, target) => {
                target.copy(source);
                controls.addEventListener('change', () => {
                    target.copy(source);
                })
            }

            if (material && (material.type === 'ShaderMaterial' || material.type === 'MeshStandardMaterial')) {
                sourcePosition = boolean ? vt.getLightPosition() : camera.position;
                targetPosition = shaderMaterial ? uniforms.lightPos.value : pointLight.position;
            }

            if (targetPosition && sourcePosition) {
                propagateLightPosition(sourcePosition, targetPosition);
            }
        })
    },

    toggleGuidingArrows(boolean) {
        const vt = window['vt'];
        for (const object of vt.flybyScene) {
            if (object && object.type === 'ArrowHelper') {
                const name = object.name.split('_')[1];
                const targetObject = vt.flybyScene.filter(e => e._id === name)[0];
                if (name === 'asteroid' && targetObject.distance && targetObject.distance.earth <= 0.00015) {
                    object.visible = false;
                } else {
                    object.visible = boolean;
                }  
            } 
        }
    },
    
    toggleHorizontalGrid(boolean) {
        const vt = window['vt'];
        vt.getScene().getObjectByProperty('type', 'GridHelper').visible = boolean;
    },

    toggleUncertaintyRegion(boolean) {
        const vt = window['vt'];
        const uncertaintyRegion = vt.getScene().getObjectByProperty('name', 'UncertaintyRegion');
        if (uncertaintyRegion) {
            uncertaintyRegion.visible = boolean;
        }
    },

    toggleOrbitRange(isVisible) {
        const orbitRangeObjects = SpacekitService.getOrbitRangeObjects();
        orbitRangeObjects.forEach(object => object.visible = isVisible);
    },

    toggleDetectionPolar(isVisible) {
        const detectionPolarObjects = SpacekitService.getDetectionPolarObjects();
        if (detectionPolarObjects.length && isVisible) {
            SynodicCalculationService.adjustDetectionPolarObjectPosition();
            SynodicCalculationService.adjustDetectionPolarMergedPosition();
        }
        detectionPolarObjects.forEach(object => object.visible = isVisible);
        SynodicCalculationService.changeElongationConeAppearance(isVisible);
    },

    changeDetectionPolarTransparency(transparencyValue) {
        const detectionPolarObjects = SpacekitService.getDetectionPolarObjects();
        detectionPolarObjects.forEach(object => object.material.opacity = 1 - transparencyValue);
    },

    changeDetectionPolarColor(color) {
        const detectionPolarObjects = SpacekitService.getDetectionPolarObjects();
        detectionPolarObjects.forEach(object => object.material.color.set(UtilsService.convertHexColorToHexNumber(color)));
    },

    getSceneObjectByName(objectName) {
        const visualisationScene = window['vt'].getScene();
        return visualisationScene.getObjectByName(objectName);
    },

    updateAsteroidsSizeFactor(value) {
        const tool = VisualisationService.getTool();
        if (tool === 'opt' || tool === 'scd') return;

        const vt = window['vt'];
        const unitsPerAu = tool === 'fvt' ? SpacekitService.VIZ_UNITS : 1;

        if (tool === 'sovt' && vt.spaceObjects.length) {
            for (const object of vt.spaceObjects) {
                SpacekitService.rescaleAsteroid(object, value, unitsPerAu)
            }
        } else {
            const asteroid = vt.flybyScene.filter(e => e._id === 'asteroid')[0];
            SpacekitService.rescaleAsteroid(asteroid, value, unitsPerAu);
        }
    },

    rescaleAsteroid(asteroid, value, unitsPerAu) {
        if (!asteroid) return;
        
        const tool = VisualisationService.getTool();
        asteroid.get3jsObjects()[0].scale.set(1, 1, 1);
        const initbox = new THREE.Box3().setFromObject( asteroid.get3jsObjects()[0] );
        const initsize = new THREE.Vector3();
        initbox.getSize(initsize);
        const diameter = !asteroid._options.diam || asteroid._options.diam === '-' ? (tool === 'fvt' ? SpacekitService.USER_DEFINED_OBJECT_FVT_DEFAULT_DIAMETER : SpacekitService.USER_DEFINED_OBJECT_DEFAULT_DIAMETER) : asteroid._options.diam;

        const initdiam = Math.sqrt(initsize.x**2 + initsize.y**2 + initsize.z**2);
        const value_r =  diameter / initdiam * 10**value * unitsPerAu / this.AU / 10**3;
        asteroid.get3jsObjects()[0].scale.set(value_r, value_r, value_r);
    },

    updateMarker(asteroid, marker){  
        
        const vt = window['vt'];
        const now = vt.getDate();
        const nowJD = UtilsService.dateToJulian(now);
        const scale = new THREE.Vector3(); 
        const settings = VisualisationService.getSettings();
        const value = settings.objectsSettings.asteroidsSizeFactor.value;
        const AU = this.AU;
        const camera = vt.getViewer().camera;
        
        if (asteroid){
            marker.visible = false;  
            const xyzObject = asteroid.getPosition(nowJD);
            xyzObject.x = xyzObject[0];
            xyzObject.y = xyzObject[1];
            xyzObject.z = xyzObject[2];

            const cameraDistance = scale.subVectors(xyzObject, camera.position).length() / SpacekitService.VIZ_UNITS * AU; 
            const astDiamKm = asteroid._options.diam * 10**value / 10**3;
            const angularSize = Math.atan2(astDiamKm, cameraDistance)*180/Math.PI;

            if (angularSize <= 0.2){
                marker.visible = true;  
            }
            
            }
            marker.position.copy(asteroid.get3jsObjects()[0].position); // Set marker position to object position
            marker.lookAt(camera.position);

            var scaleVector = new THREE.Vector3();    
            var size = 700;
            var scaleFactor = scaleVector.subVectors(marker.position, camera.position).length() / size;

            // Apply the scale factor to the marker to maintain constant size
            marker.scale.set(scaleFactor, scaleFactor, scaleFactor);
    },

    addAsteriodMarker(){
        const vt = window['vt'];
        const asteroid = vt.flybyScene.filter(e => e._id === 'asteroid')[0];
        const scene = vt.getScene();
        const camera = vt.getViewer().camera;
        const settings = VisualisationService.getSettings().objectsSettings;

        const geometry = new THREE.RingGeometry( 7, 10, 100, 1, 0, Math.PI*2); 
        const material = new THREE.MeshBasicMaterial( { 
            color: settings.asteroidOrbit.color, 
            wireframe: false, 
            side: THREE.DoubleSide, 
            transparent: true,
            opacity: .75 
        } );

        const marker = new THREE.Mesh( geometry, material ); 
        marker.name = 'marker_asteroid';
        scene.add(marker);
        vt.flybyScene.push(marker)
        asteroid._label3d[0].visible = true;

        //vt.getRenderer().render(scene, camera);

        // Render loop
        function animate() {
            // Update the marker position
            SpacekitService.updateMarker(asteroid, marker);

            marker.renderOrder = 1000;

            // Render the scene
            if (vt.getRenderer()){
            vt.getRenderer().render(scene, camera);
            vt.getRenderer().render(vt.sceneOrtho, vt.cameraOrtho);
            }

            // Request the next frame
            requestAnimationFrame(animate);
        }

        // Start the animation loop
        animate();
        
    },


    updateUncertaintyDensity(value) {
        const vt = window['vt'];
        const uncertaintyRegions = vt.flybyScene.filter(e => e.name === 'UncertaintyRegion')[0];
        if (uncertaintyRegions) {
            const length = uncertaintyRegions.children.length;
            const middlePoint = Math.round(length / 2);
            if (!value) {
                for (const mesh of uncertaintyRegions.children) {
                    mesh.visible = false;
                }
            } else if (value === length) {
                for (const mesh of uncertaintyRegions.children) {
                    mesh.visible = true;
                }
            } else {
                const visiblePositions = [];
                const delta = length / (value + 1);
                for (let i = 0; i < length; i = i + delta) {
                    const roundIndex = Math.round(i);
                    if (roundIndex && roundIndex !== length) {
                        visiblePositions.push(roundIndex);
                    }
                }
                if (visiblePositions.indexOf(middlePoint) === -1) {
                    visiblePositions[0] = middlePoint;
                }
                for (const [index, mesh] of uncertaintyRegions.children.entries()) {
                    if (visiblePositions.indexOf(index) > -1) {
                        mesh.visible = true;
                    } else {
                        mesh.visible = false;
                    }
                }
            }
        }
    },

    updateUncertaintyFactor(value) {
        const vt = window['vt'];
        const uncertaintyRegions = vt.flybyScene.filter(e => e.name === 'UncertaintyRegion')[0];
        if (uncertaintyRegions) {
            for (const mesh of uncertaintyRegions.children) {
                mesh.scale.set(value, value, value);
            }
        }
    },

    updateUncertaintyColor(value) {
        const vt = window['vt'];        
        const uncertaintyRegions = vt.flybyScene.filter(e => e.name === 'UncertaintyRegion')[0];
        if (uncertaintyRegions) {
            for (const mesh of uncertaintyRegions.children) {
                mesh.material.color.setHex(UtilsService.hexColor(value));                
            }
        }             
    },

    updateNamesColor(name,color) {
        HUDService.updateLabelsColor(name,color);                         
    },
    updateNamesSize(name,size) {        
        HUDService.updateLabelsSize(name,size);             
    },
    updateOrbitColor(name, color){
        const vt = window['vt'];
        const spaceObject = vt.flybyScene.filter(e => e._id === name)[0];        
        const orbitColor = new THREE.Color(UtilsService.hexColor(color));
        if (spaceObject) {                    
            spaceObject.getOrbit().setHexColor(orbitColor);                        
        }  
        // Update arrow color
        this.updateArrowHelperColor(name, color);   
        this.updateMarkerColor(name, color);         
    },    
    updateEarthOrbitColor(color){
        const vt = window['vt'];
        const earth = vt.planets.filter(e => e._id === 'earth')[0];                
        if (earth) {                    
            const orbitColor = new THREE.Color(UtilsService.hexColor(color));
            earth.getOrbit().setHexColor(orbitColor);                     
        }           
    },

    updateSynodicEarthOrbitColor(color){  
        const averagedEarthOrbitObject = SpacekitService.getSceneObjectByName('averagedEarthOrbit');
        averagedEarthOrbitObject && (averagedEarthOrbitObject.material.color.set(UtilsService.hexColor(color)));   
    },

    updatePlanetsOrbitsColor(color){
        const vt = window['vt'];
        const planets = vt.planets.filter(e => e._id !== 'earth');                
        const orbitColor = new THREE.Color(UtilsService.hexColor(color));
        for (const planet of planets) {
            planet.getOrbit().setHexColor(orbitColor);            
        }        
    }, 
    updateKeplerianOrbitsColor(color){
        const vt = window['vt'];
        if (VisualisationService.getTool() === 'sovt') {
            SpacekitService.handleSynodicOrbitHiglight();
            return;
        }
        const keplerianObjects = vt.spaceObjects.filter(o => !o._id.includes('_perturbed'));        
        const orbitColor = new THREE.Color(UtilsService.hexColor(color));
        for (const spaceObject of keplerianObjects) {
            spaceObject.getOrbit().setHexColor(orbitColor);                  
            const selected = VisualisationService.getSelectedObjectName();
            if (selected && spaceObject._options.name.includes(selected)){
                SpacekitService.turnOnOrbit(spaceObject, orbitColor);
            }else{
                SpacekitService.turnOffOrbit(spaceObject);
            }
        }                 
        // Update Box line color
        
    }, 
    updatePerturbedOrbitsColor(color){
        const vt = window['vt'];
        if (VisualisationService.getTool() === 'sovt') {
            SpacekitService.handleSynodicOrbitHiglight();
            return;
        }
        const perturbedObjects = vt.spaceObjects.filter(o => o._id.includes('_perturbed'));        
        const orbitColor = new THREE.Color(UtilsService.hexColor(color));
        for (const spaceObject of perturbedObjects) {
            spaceObject.getOrbit().setHexColor(orbitColor);                    
            const selected = VisualisationService.getSelectedObjectName();
            if (selected && spaceObject._options.name.includes(selected)){
                SpacekitService.turnOnOrbit(spaceObject, orbitColor);
            }else{
                SpacekitService.turnOffOrbit(spaceObject);
            }                                           
        }                        
        // Update Box line color
        
    }, 
    toggleGeostationaryOrbit(boolean) {
        const vt = window['vt'];
        const orbit = vt.getScene().getObjectByProperty('name', 'GeostationaryOrbit');
        if (orbit) {
            orbit.visible = boolean;
        }
    },

    toggleAxisGuide(boolean) {
        const vt = window['vt'];
        vt.getScene().getObjectByProperty('type', 'AxesHelper').visible = boolean;
    },   
    toggleSpaceObject(name, boolean) {
        const vt = window['vt'];
        const spaceObject = vt.flybyScene.filter(e => e._id === name)[0];        
        if (spaceObject) {            
            spaceObject.get3jsObjects()[0].visible = boolean;            
            HUDService.updateLabelVisibility(name,boolean);            
        }    
    },    

    toggleOrbit(name, boolean) {
        const vt = window['vt'];
        const spaceObject = vt.flybyScene.filter(e => e._id === name)[0];        
        if (spaceObject) {            
            spaceObject.get3jsObjects()[2].visible = boolean;
        }   
    },       
        
    togglePlanets(prop, boolean, earth = false) {
        const vt = window['vt'];
        if (prop === 'name') {
            if (earth) {
                boolean ? vt.cameraOrtho.layers.enable(1) : vt.cameraOrtho.layers.disable(1);
            } else {
                boolean ? vt.cameraOrtho.layers.enable(0) : vt.cameraOrtho.layers.disable(0);
            }
        } else {
            let planets = [];
            if (earth) {
                planets = vt.planets.filter(e => (e._id === 'earth'));
            } else {
                planets = vt.planets.filter(e => (e._id !== 'earth'));
            }
            for (const planet of planets) {
                if (prop === 'orbit' && planet.get3jsObjects()[2]) {
                    planet.get3jsObjects()[2].visible = boolean;
                    planet.getOrbit().visible = boolean;
                }
            }
        }
        if (prop === 'orbit' && earth && VisualisationService.getTool() === 'sovt') {
            SpacekitService.toggleAveragedEarthOrbit(boolean);
        }
    },

    setZoom(zoom) {
        const tool = VisualisationService.getTool();
        const vt = window['vt'];
        VisualisationService.setZoom(zoom);
        if (tool && tool === 'ovt')
            VisualisationService.setZoomOVT(zoom);
        else if (tool && tool === 'fvt'){
            VisualisationService.setZoomFVT(zoom);
        }

        const cameraPosition = vt.getViewer().camera.position;
        const cameraTarget = vt.getViewer().cameraControls.target;
        cameraPosition.sub(cameraTarget).setLength(zoom).add(cameraTarget);
    },

    setOrientation(){
        const tool = VisualisationService.getTool();

        if (tool !== 'opt' && tool !== 'scd'){
            const vt = window['vt'];
            let camera = vt.getViewer().camera;
            const loadedState = {...MemoryService.getToolState()};
            
            if (loadedState && loadedState.OVTcamera && tool === 'ovt'){
                
                camera.position.x = loadedState.OVTcamera.pos.x;
                camera.position.y = loadedState.OVTcamera.pos.y;
                camera.position.z = loadedState.OVTcamera.pos.z;

                camera.rotation.x = loadedState.OVTcamera.rot.x;
                camera.rotation.y = loadedState.OVTcamera.rot.y;
                camera.rotation.z = loadedState.OVTcamera.rot.z;

                camera.quaternion.w = loadedState.OVTcamera.quat.w;
                camera.quaternion.x = loadedState.OVTcamera.quat.x;
                camera.quaternion.y = loadedState.OVTcamera.quat.y;
                camera.quaternion.z = loadedState.OVTcamera.quat.z;

            } else if (loadedState && loadedState.FVTcamera && tool === 'fvt'){

                camera.position.x = loadedState.FVTcamera.pos.x;
                camera.position.y = loadedState.FVTcamera.pos.y;
                camera.position.z = loadedState.FVTcamera.pos.z;

                camera.rotation.x = loadedState.FVTcamera.rot.x;
                camera.rotation.y = loadedState.FVTcamera.rot.y;
                camera.rotation.z = loadedState.FVTcamera.rot.z;

                camera.quaternion.w = loadedState.FVTcamera.quat.w;
                camera.quaternion.x = loadedState.FVTcamera.quat.x;
                camera.quaternion.y = loadedState.FVTcamera.quat.y;
                camera.quaternion.z = loadedState.FVTcamera.quat.z;

            }
        }
        //cameraPosition.sub(cameraTarget).setLength(zoom).add(cameraTarget);
    },

    updateDisplayLimiters() {
        const vt = window['vt'];
        const smallObjects = vt.smallObjects;
        const settings = VisualisationService.getSettings();
        
        const minDiameter = settings.objectsSettings.diameter.value[0];
        const maxDiameter = settings.objectsSettings.diameter.value[1];
        const minMagnitude = settings.objectsSettings.magnitude.value[0];
        const maxMagnitude = settings.objectsSettings.magnitude.value[1];
        
        for (const smallObject of smallObjects) {
            if (smallObject._options.mag && smallObject._options.diam) {
                if (smallObject._options.diam*1 >= minDiameter && smallObject._options.diam*1 <= maxDiameter && smallObject._options.mag*1 >= minMagnitude && smallObject._options.mag*1 <= maxMagnitude) {
                    SpacekitService.showParticle(smallObject);
                } else {
                    SpacekitService.hideParticle(smallObject);
                }
            }
        }
    },

    resizeParticles(size) {
        const vt = window['vt'];
        const smallObjects = vt.smallObjects;
        for (const smallObject of smallObjects) {
            SpacekitService.resizeParticle(smallObject, size);
        }
        VisualisationService.setParticleSize(size);
    },

    updateGroupColor(smallObjectsGroup, color) {
        for (const smallObject of smallObjectsGroup) {
            SpacekitService.updateParticleColor(smallObject, color);
        }
    },

    hideParticle(smallObject) {
        smallObject.hidden = true;
        SpacekitService.setParticleSize(smallObject._context.objects.particles, smallObject._particleIndex, 0, [999999, 999999, 999999]);
    },

    showParticle(smallObject) {
        smallObject.hidden = false;
        SpacekitService.setParticleSize(smallObject._context.objects.particles, smallObject._particleIndex, smallObject.particleSize || smallObject._options.particleSize, [0,0,0]);
    },

    resizeParticle(smallObject, size) {
        smallObject.particleSize = size;
        if (!smallObject.hidden) {
            SpacekitService.setParticleSize(smallObject._context.objects.particles, smallObject._particleIndex, size, [0,0,0]);
        }
    },

    updateParticleColor(smallObject, hexColor) {
        const numColor = UtilsService.hexColor(hexColor);
        const color = new THREE.Color(numColor);
        SpacekitService.setParticleColor(smallObject._context.objects.particles, smallObject._particleIndex, color);
    },

    setParticleSize(particles, offset, size, origin) {
        const attributes = particles.attributes;
        attributes.size.set([size], offset);
        attributes.origin.set(origin, offset * 3);

        for (const attributeKey in attributes) {
            attributes[attributeKey].needsUpdate = true;
        }
    },

    setParticleColor(particles, offset, color) {
        const attributes = particles.attributes;
        attributes.fuzzColor.set([color.r, color.g, color.b], offset * 3);
        for (const attributeKey in attributes) {
            attributes[attributeKey].needsUpdate = true;
        }
    },

    setCameraPosition(x, y, z) {
        const timeout = 33;
        setTimeout(() => {
            const vt = window['vt'];
            vt.getViewer().camera.position.set(x, y, z);
            const settings = VisualisationService.getSettings();
            const zoom = settings.zoom.value;
            
            if (z > 0) {
                const controls = vt.getViewer().cameraControls;
                controls.minAzimuthAngle = 0;
                controls.maxAzimuthAngle = 0;
                controls.update();
                setTimeout(() => {
                    controls.minAzimuthAngle = -Infinity;
                    controls.maxAzimuthAngle = Infinity;
                    controls.update();
                }, 50);
            }

            SpacekitService.setZoom(Math.pow(zoom, 2));
        }, timeout);
    },

    createSun(jds, suneph, sundir) {
        const vt = window['vt'];
        const sunData = suneph ? suneph.map(e => [e.x, e.y, e.z]) : [];
        const sunDir = [sundir[0].x, sundir[0].y, sundir[0].z];
        if (!sunData.length) {
            for (let i = 0; i < jds.length; i++) {
                sunData.push(sunDir);
            }
        }
        const sunEphemTable = SpacekitService.mapEphemeridesTableForFlyby(jds, sunData);
        const params = VisualisationService.getMainBodyParameters('sun');
        const rotation = SpacekitService.mapObjectRotation(params);
        const sunOrbitColor = UtilsService.hexColor(VisualisationService.getSettings().objectsSettings.sunOrbit.color);
        const sun = vt.createSphere('sun', {
            textureUrl: '/static/textures/detailed/sun.jpg',
            radius: params.radius,
            name: params.name,
            labelLayer: 2,
            rotation,
            ephemTable: sunEphemTable,
            theme: {
                color: SpacekitService.COLOR_SUN,
                orbitColor: sunOrbitColor
            }
        });
        sun._object3js.name = 'sun';

        const sunMidPosition = sun.getPosition(jds[Math.floor(jds.length / 2)]);
        vt.createLight(sunMidPosition);

        vt.flybyScene.push(sun);
        const sunLabelColor = UtilsService.hexColor(VisualisationService.getSettings().objectsSettings.objectNames.color);        
        HUDService.createLabel(sun, sunLabelColor);
    },

    createFlybyScene(jds, center, suneph, mooneph, asteph, shape, name, designator, diam, mag, sundir, ellipsoids, isSyntheticObject = false, flybyTime = null) {
        SpacekitService.createSun(jds, suneph, sundir);
        SpacekitService.createMoon(jds, mooneph);
        SpacekitService.createAsteroidFlyby(jds, asteph, shape, name, designator, suneph, diam, mag, ellipsoids, isSyntheticObject);
        SpacekitService.createMainBody(jds, center, flybyTime);

        const settings = VisualisationService.getSettings();
        const labelsVisibility = settings.objectsSettings.objectNames.value;
        const magnitudeLabelsVisibility = settings.objectsSettings.limitingMagnitudeLegend.value;
        const closestApproachPoint = settings.objectsSettings.closestApproachPoint.value;
        const lightEffect = settings.objectsSettings.lightEffect.value;
        const uncertaintyFactor = settings.objectsSettings.uncertaintyFactor.value;
        const uncertaintyDensity = settings.objectsSettings.uncertaintyDensity.value;
        const horizontalPlane = settings.objectsSettings.horizontalPlane.value;
        const uncertaintyRegion = settings.objectsSettings.uncertaintyRegion.value;
        SpacekitService.toggleObjectsNames(labelsVisibility);
        SpacekitService.toggleMagnitudeLimitLabels(magnitudeLabelsVisibility);
        SpacekitService.toggleClosestApproachPoint(closestApproachPoint);
        SpacekitService.toggleLightEffect(lightEffect);
        SpacekitService.toggleHorizontalPlane(horizontalPlane);
        SpacekitService.toggleUncertaintyRegion(uncertaintyRegion);
        SpacekitService.updateUncertaintyFactor(uncertaintyFactor);
        SpacekitService.updateUncertaintyDensity(uncertaintyDensity);  
    },

    removeFlybyScene() {
        const vt = window['vt'];
        while (vt.flybyScene.length) {
            const toRemove = vt.flybyScene[vt.flybyScene.length - 1];
            if (toRemove._label3d && toRemove._label3d.length && toRemove._label3d.length>1){
                for  (let i = 0; i < toRemove._label3d.length; i++){
                    vt.sceneOrtho.remove(toRemove._label3d[i]);
                }
            } else if (toRemove._label3d) {
                vt.sceneOrtho.remove(toRemove._label3d);
                HUDService.removeLabel(toRemove);
            }
            if (!toRemove.uuid) {
                vt.removeObject(toRemove);
            } else {
                vt.getScene().remove(toRemove);
            }
            vt.flybyScene.splice(vt.flybyScene.length - 1, 1);
        }
        VisualisationService.setFlybyClosestPoint(null);     
    },

    createMoon(jds, mooneph) {
        if (!mooneph || !mooneph.length) {
            return;
        }
        const vt = window['vt'];
        const moonData = mooneph.map(e => [e.x, e.y, e.z]);
        const moonEphemTable = SpacekitService.mapEphemeridesTableForFlyby(jds, moonData);
        const params = VisualisationService.getMainBodyParameters('moon');
        const rotation = SpacekitService.mapObjectRotation(params);
        const timespan = jds[jds.length - 1] - jds[0];
        const moonOrbitColor = UtilsService.hexColor(VisualisationService.getSettings().objectsSettings.moonOrbit.color);
        const moon = vt.createSphere('moon', {
            textureUrl: '/static/textures/detailed/moon.jpg',
            radius: params.radius,
            name: params.name,
            labelLayer: 2,
            orbitPathSettings: {
                numberSamplePoints: 2 * jds.length,
                trailDurationYears: timespan / 365.25,
                leadDurationYears: timespan / 365.25
            },
            rotation,
            ephemTable: moonEphemTable,
            theme: {               
                orbitColor: moonOrbitColor
            },
            ecliptic: {
                lineColor: 0x333333,
                displayLines: false,
            }
        });
        moon._object3js.name = 'moon';

        vt.flybyScene.push(moon);   
        const moonLabelColor = UtilsService.hexColor(VisualisationService.getSettings().objectsSettings.objectNames.color);        
        HUDService.createLabel(moon, moonLabelColor);
    },

    createAsteroidFlyby(jds, asteph, shapes, name, designator, suneph, diam, mag, ellipsoids, isSyntheticObject) {
        const vt = window['vt'];
        const {shape, rotation} = SpacekitService.prepareShapeAndRotation(shapes);
        const asteroidData = asteph.map(e => [e.x, e.y, e.z]);
        const asteroidEphemTable = SpacekitService.mapEphemeridesTableForFlyby(jds, asteroidData);
        const timespan = jds[jds.length - 1] - jds[0] + 1;
        const numberSamplePoints = jds.length > 240 ? 2 * jds.length : 10 * jds.length;
        const closestApproachPoint = SpacekitService.createClosestApproachPoint(asteroidEphemTable.data);
        vt.getScene().add(closestApproachPoint);

        const uncertaintyRegion = !isSyntheticObject ? SpacekitService.createUncertaintyRegion(asteroidEphemTable.data, ellipsoids) : null;
        uncertaintyRegion && vt.getScene().add(uncertaintyRegion);
  
        const asteroidOrbitColor = UtilsService.hexColor(VisualisationService.getSettings().objectsSettings.asteroidOrbit.color);        
        const asteroid = vt.createShape('asteroid', {
            shape,
            rotation,
            name,
            labelLayer: 2,
            designator,
            diam,
            mag,
            suneph: !!suneph,
            ephemTable: asteroidEphemTable,
            orbitPathSettings: {
                numberSamplePoints,
                trailDurationYears: timespan / 365.25,
                leadDurationYears: timespan / 365.25
            },
            ecliptic: {
                displayLines: true,
            },
            theme: {
                color: SpacekitService.COLOR_KEPLERIAN_SELECTED,
                orbitColor: asteroidOrbitColor
            }
        });
        asteroid._object3js.name = 'asteroid';

        //asteroid.initRotation();

        const e = asteroid._options.rotation;
        var t = Math.PI;
        var n = THREE.MathUtils.degToRad(e.lambdaDeg || 0);
        var i = THREE.MathUtils.degToRad(e.betaDeg||0);

        asteroid.get3jsObjects()[0].rotateY(-(t/2-i));
        asteroid.get3jsObjects()[0].rotateZ(-n);

        asteroid._options.name = name;

        vt.flybyScene.push(asteroid);
        vt.flybyScene.push(closestApproachPoint);
        uncertaintyRegion && vt.flybyScene.push(uncertaintyRegion);

        setTimeout(() => {     
            SpacekitService.updateAsteroidsSizeFactor(VisualisationService.getSettings().objectsSettings.asteroidsSizeFactor.value);
        }, 1000);
        const asteroidLabelColor = UtilsService.hexColor(VisualisationService.getSettings().objectsSettings.objectNames.color);
        HUDService.createLabel(asteroid, asteroidLabelColor);
        HUDService.createLabel(closestApproachPoint);
    },

    getLinesToEcliptic(spaceObject) {  
        const tool = VisualisationService.getTool();
        const orbitObject = spaceObject._orbit;
        if (!orbitObject.orbitPoints) {
          // Generate the orbitPoints cache.
          orbitObject.getOrbitShape();
        }
        // Place a cap on visible lines, for large or highly inclined orbits.
        const points = orbitObject.orbitPoints || [];
        let samplingStep, N;

        let filteredPoints = [];
        const totalPoints = points.length;

        if (tool === 'ovt' || tool === 'sovt'){
 
            const trailDurationYears = spaceObject._options.orbitPathSettings.trailDurationYears;
            const leadDurationYears = spaceObject._options.orbitPathSettings.leadDurationYears;
            const kepOrbitPeriod = spaceObject._options.keplerianOrbitPeriod;
            const partOrbit = kepOrbitPeriod >= 365.25 * (trailDurationYears + leadDurationYears);
    
            if (orbitObject.ephem.attrs && orbitObject.ephem.attrs.e >= 0.9){
                N = 360;
            } else if (orbitObject.ephem.attrs && orbitObject.ephem.attrs.e < 0.9) {
                N = 90;
            }

            if (!orbitObject.ephem.attrs){
                N = 360;
                const jdstart = spaceObject._orbit.ephem.data[0][0];
                const jdstop = spaceObject._orbit.ephem.data[spaceObject._orbit.ephem.data.length-1][0];
                const pertDaysTotal = jdstop - jdstart;
                const incompData = pertDaysTotal < kepOrbitPeriod;
                if(partOrbit && !incompData){
                    const partOrbitValue = 365.25 * (trailDurationYears + leadDurationYears) / kepOrbitPeriod;
                    N = 360 * partOrbitValue;
                } else if (incompData) {
                    const partOrbitValue = totalPoints / (2 * kepOrbitPeriod);
                    N = 360 * partOrbitValue;
                }
            }

            samplingStep = totalPoints / N; // Calculate the sampling step as a float

        } else if (tool === 'fvt'){
            samplingStep = 10;
            N = totalPoints / samplingStep;
        }

        // Select N points with equal spacing
        for (let i = 0; i < N; i++) {
            const index = Math.floor(i * samplingStep) % totalPoints; // Calculate the index based on the float samplingStep
            const vertex = points[index];
            filteredPoints.push(vertex);
            filteredPoints.push(new THREE.Vector3(vertex.x, vertex.y, 0));
        }

        const geometry = new THREE.BufferGeometry().setFromPoints(filteredPoints);
        orbitObject.eclipticDropLines = new THREE.LineSegments(
          geometry,
          new THREE.LineBasicMaterial({
            color: orbitObject.options.eclipticLineColor || 0x333333,
            blending: THREE.AdditiveBlending,
          }),
        );
    
        return orbitObject.eclipticDropLines;
    },

    createClosestApproachPoint(positions) {
        const closestApproachPointMaterial = new THREE.MeshBasicMaterial({ color: SpacekitService.COLOR_CLOSEST_POINT });
        const closestApproachPointGeometry = new THREE.SphereGeometry(0.01, 16, 16);
        const closestApproachPoint = new THREE.Mesh(closestApproachPointGeometry, closestApproachPointMaterial);
        closestApproachPoint.name = 'ClosestApproachPoint';
        closestApproachPoint._id = 'ClosestApproachPoint';

        let closestPosition = [];
        let distance = null;
        for (const position of positions) {
            const currentDistance = VisualisationService.distanceToCenter([position[1], position[2], position[3]], 10);
            if (!distance || currentDistance < distance) {
                distance = currentDistance;
                closestPosition = position;
            }
        }
        VisualisationService.setFlybyClosestPoint(closestPosition[0]);
        closestApproachPoint.position.set(closestPosition[1] * SpacekitService.VIZ_UNITS, closestPosition[2] * SpacekitService.VIZ_UNITS, closestPosition[3] * SpacekitService.VIZ_UNITS);
        closestApproachPoint._options = {
            name: Keys.t.closestApproachPoint,
            labelLayer: 2,
        }

        return closestApproachPoint;
    },

    createUncertaintyRegion(positions, ellipsoids) {
        const deg2rad = Math.PI/180;
        const ellipsoidGroup = new THREE.Group();
        ellipsoidGroup.name = 'UncertaintyRegion';
        const ellipsoidMaterial = new THREE.MeshBasicMaterial({ color: SpacekitService.COLOR_SUN, transparent: true, wireframe: true});
        if (ellipsoids && ellipsoids.length) {
            for (const [index, position] of positions.entries()) {
                const ell = ellipsoids[index];
                const semiAxes = [ell['sa_1'], ell['sa_2'], ell['sa_3']];
                const rotationSteps = [ell['rotx'] * deg2rad, ell['roty'] * deg2rad, ell['rotz'] * deg2rad];
                const ellSize = semiAxes.map(e => e * SpacekitService.VIZ_UNITS);
                
                const ellipsoidGeometry = new THREE.SphereGeometry(1, 24, 24);
                ellipsoidGeometry.applyMatrix4(new THREE.Matrix4().makeScale(ellSize[0], ellSize[1], ellSize[2]));

                const ellipsoid = new THREE.Mesh(ellipsoidGeometry, ellipsoidMaterial);
                ellipsoid.position.set(position[1] * SpacekitService.VIZ_UNITS, position[2] * SpacekitService.VIZ_UNITS, position[3] * SpacekitService.VIZ_UNITS);
                ellipsoid.geometry.rotateX(rotationSteps[0]);
                ellipsoid.geometry.rotateY(rotationSteps[1]);
                ellipsoid.geometry.rotateZ(rotationSteps[2]);
                ellipsoidGroup.add(ellipsoid);
            }
        }

        return ellipsoidGroup;
    },

    createMainBody(jds, center, flybyTime = null) {
        const vt = window['vt'];
        const params = VisualisationService.getMainBodyParameters(center);
        const mainbody = vt.createSphere('mainbody', {
            textureUrl: `/static/textures/detailed/${center}.jpg`,
            radius: params.radius,
            name: params.name,
            labelLayer: 2,
            atmosphere: {
                enable: params.atmosphere,
                outerSizeRatio: 0.1,
            },
            period: params.period,
            jd0: params.jd0,
            lambda: params.lambda,
        })
        mainbody._object3js.name = 'mainbody';

        if (center === 'saturn') {
            mainbody.addRings(74270.580913, 140478.924731, '/static/textures/detailed/saturn_rings.png');
        }
        vt.flybyScene.push(mainbody);
        HUDService.createLabel(mainbody);
        
        const getostationaryOrbit = SpacekitService.createGeostationaryOrbit(params.geostationaryOrbit);
        vt.getScene().add(getostationaryOrbit);
        vt.flybyScene.push(getostationaryOrbit)

        const lambda = params.lambda * Math.PI/180;
        mainbody.get3jsObjects()[0].rotateZ(lambda);

        let jd = jds[0] + 2400000.5;

        if (flybyTime) {
            const currentFlybyDate = new Date(flybyTime);
            jd = UtilsService.dateToJulian(currentFlybyDate);
        }

        setTimeout(() => {
            VisualisationService.updateRotationAngle(params.jd0, jd, true);
        }, 200);

        const length = params.radius * SpacekitService.VIZ_UNITS * 4;
        const settings = VisualisationService.getSettings().objectsSettings;
        
        const sunColor = settings.sunOrbit.color;
        const moonColor = settings.moonOrbit.color;
        const asteroidColor = settings.asteroidOrbit.color;       
        SpacekitService.createArrow('sun', jd, length, new THREE.Color(UtilsService.hexColor(sunColor)));        
        SpacekitService.createArrow('moon', jd, length, new THREE.Color(UtilsService.hexColor(moonColor)));
        SpacekitService.createArrow('asteroid', jd, length, new THREE.Color(UtilsService.hexColor(asteroidColor)));
        SpacekitService.addAsteriodMarker();
        
        const showArrows = settings.guidingArrows.value;
        const showOrbit = settings.geostationaryOrbit.value;
        SpacekitService.toggleGuidingArrows(showArrows);
        SpacekitService.toggleGeostationaryOrbit(showOrbit);
        //VisualisationService.setSelectedObjectName(center);
        SpacekitService.setMinCameraDistance(SpacekitService.getMinCameraDistance(params.radius));
    },

    prepareShapeAndRotation(shapes) {
        const shape = {
            shapeUrl: '/static/shapes/generic_shape.obj',
            modelSource: 'generic',
        }

        const rotation = {
            lambdaDeg: 251,
            betaDeg: -63,
            period: 3.755067,
            yorp: 1.9e-8,
            phi0: 0,
            jd0: 2443568.0,
        }

        if (shapes.asteroidsShapes && shapes.asteroidsShapes.length && shapes.asteroidsShapes[0].models && shapes.asteroidsShapes[0].models.length) {
            const model = shapes.asteroidsShapes[0].models.filter(e => e.shape !== '')[0];
            if (model) {
                shape.shapeUrl = '/shapes' + model.shape;
                shape.modelSource = model.source;
            }

            const rotationParams = shapes.asteroidsShapes[0].models.filter(e => e.lambdaDeg !== 'N/A')[0];
            if (rotationParams) {
                for (const key in rotationParams) {
                    if (key === 'shape' || key === 'source') continue;
                    rotation[key] = rotationParams[key];
                }
            }
        }

        return {
            shape,
            rotation
        }
    },

    mapObjectRotation(params) {
        return {
            enable: true,
            speed: 1 / params.period
        }
    },

    createArrow(targetName, jd, length, hex) {
        const vt = window['vt'];
        const object = vt.flybyScene.filter(e => e._id && e._id === targetName)[0];
        if (object) {
            const targetPosition = object.getPosition(jd);
            const target = new THREE.Vector3(targetPosition[0], targetPosition[1], targetPosition[2]).normalize();
            const origin = new THREE.Vector3(0, 0, 0);
            const arrow = new THREE.ArrowHelper(target, origin, length, hex);
            arrow.name = 'arrow_' + targetName;
            vt.getScene().add(arrow);
            vt.flybyScene.push(arrow);
        }
    },
    updateMarkerColor(name, color){
        const markerName = 'marker_' + name;
        const vt = window['vt'];
        const marker = vt.flybyScene.filter(e => e.name === markerName)[0]; 
        const newMaterial = new THREE.MeshBasicMaterial({ color: color });        
        if (marker) {
            marker.material = newMaterial;
        }          
    },
    updateArrowHelperColor(name, color){
        const arrowName = 'arrow_' + name;
        const vt = window['vt'];
        const arrow = vt.flybyScene.filter(e => e.name === arrowName)[0];        
        if (arrow) {
            arrow.setColor(color);
        }          
    },

    updateArrows(jd) {
        const settings = VisualisationService.getSettings();
        const vt = window['vt'];
        for (const object of vt.flybyScene) {
            if (object && object.type === 'ArrowHelper') {
                const name = object.name.split('_')[1];
                const targetObject = vt.flybyScene.filter(e => e._id === name)[0];
                if (name === 'asteroid' && targetObject.distance && targetObject.distance.earth <= 0.00015) {
                    object.visible = false;
                } else {
                    object.visible = settings.objectsSettings.guidingArrows.value;
                }  
                if (object.visible) {
                    const targetPosition = targetObject.getPosition(jd);
                    const target = new THREE.Vector3(targetPosition[0], targetPosition[1], targetPosition[2]).normalize();
                    object.setDirection(target);
                }
            }
        }
    },

    enableSovtToolFeatures() {
        const settings = VisualisationService.getSettings().objectsSettings;
        const isMagnitudeLabelVisible = settings.limitingMagnitudeLegend.value;
        const isEarthNameVisible = settings.earthName.value;
        const isObjectsNamesVisible = settings.objectNames.value;
        SpacekitService.enableSovtLightEffect();
        SpacekitService.setZAxisView();
        SpacekitService.enableEclipticGrid();
        SpacekitService.createAveragedEarthOrbit();
        SpacekitService.toggleEarthName(isEarthNameVisible);
        SpacekitService.toggleObjectsNames(isObjectsNamesVisible);
        SpacekitService.toggleMagnitudeLimitLabels(isMagnitudeLabelVisible);
    },

    enableSovtLightEffect() {
        const vt = window['vt'];
        vt.createLight();
    },

    setZAxisView() {
        VisualisationService.viewAxes('z');
    },

    enableEclipticGrid() {
        VisualisationService.updateSetting({
            group: 'objectsSettings',
            change: {
                option: 'horizontalGrid',
                property: 'value',
                value: true,
            }
        });  
    },

    createAveragedEarthOrbit() {
        const scene = window['vt'].getScene();
        const settings = VisualisationService.getSettings();
        const isEarthOrbitVisible = settings.objectsSettings.earthOrbit.value;
        const averagedEarthOribtPoints = SynodicCalculationService.calculateAveragedEarthOrbitPoints().map(([x, y, z]) => new THREE.Vector3(x, y, z));
        const averagedEarthOrbitMaterial = new THREE.LineBasicMaterial({ color: UtilsService.hexColor(settings.objectsSettings.synodicEarthOrbit.color) });
        const averagedEarthOrbitGeometry = new THREE.BufferGeometry().setFromPoints(averagedEarthOribtPoints);
        const averagedEarthOrbitObject = new THREE.Line(averagedEarthOrbitGeometry, averagedEarthOrbitMaterial);
        averagedEarthOrbitObject.visible = isEarthOrbitVisible;
        averagedEarthOrbitObject.name = 'averagedEarthOrbit';
        scene.add(averagedEarthOrbitObject);
    },

    toggleAveragedEarthOrbit(isVisible) {
        const averagedEarthOrbitObject = SpacekitService.getSceneObjectByName('averagedEarthOrbit');
        averagedEarthOrbitObject && (averagedEarthOrbitObject.visible = isVisible);
    },

    getSpaceObjectData(ephemData) {
        const vt = window["vt"];
        if (!vt || !vt.simulationElt) {
            return null;
        }
        const diam = ephemData.diam ? ephemData.diam : UtilsService.estimateDiameter(ephemData.mag);
        const ephem = SpacekitService.mapEphemerides(ephemData);
        const spaceObjectOptions = {
            ...SpacekitService.spaceObjectOptions(),
            ...{
                name: ephemData.designator,
                diam,
                mag: ephemData.mag,
                labelLayer: 2,
                ephem,
            },
        };
        const spaceObject = vt.createSphere(
            ephemData.catalogueNumber ? ephemData.catalogueNumber.toString() : ephemData.designator.toString(),
            spaceObjectOptions
        );
        vt.removeObject(spaceObject);

        // workaround for objects with e > 0.9 and period
        // should be removed after spacekit.js library upgrade
        if (ephem.attrs.e > 0.9 && ephem.attrs.period) {
            spaceObject._orbit.orbitType = 3;
            spaceObject._orbitPath = null;
            spaceObject.update(vt.getJd(), true);
            vt.removeObject(spaceObject)
        }

        return spaceObject;
    },

    addOrbitRange(perihelionCircumferenceData, aphelionCircumferenceData) {
        const settings = VisualisationService.getSettings();
        const isOrbitRangeVisible = settings.objectsSettings.orbitRange.value;
        const orbitRangeObjectsData = [
            {pointsData: perihelionCircumferenceData, name: 'perihelionOrbit'},
            {pointsData: aphelionCircumferenceData, name: 'aphelionOrbit'}
        ];
        orbitRangeObjectsData.forEach(({pointsData, name}) => SpacekitService.createOrbitRangeObject(pointsData, isOrbitRangeVisible, name));
    },

    createOrbitRangeObject(pointsData, isOrbitRangeVisible, name) {
        const scene = window['vt'].getScene();
        const orbitRangeMaterial = SpacekitService.getOrbitRangeMaterial();
        const orbitRangeObjectLimitPoints = [];
        pointsData.forEach(([x, y]) => orbitRangeObjectLimitPoints.push(new THREE.Vector2(x, y)));
        const orbitRangeObjectGeometry = new THREE.LatheGeometry(orbitRangeObjectLimitPoints, 64);
        const orbitRangeObject = new THREE.Mesh(orbitRangeObjectGeometry, orbitRangeMaterial);
        orbitRangeObject.name = name;
        orbitRangeObject.visible = isOrbitRangeVisible;
        scene.add(orbitRangeObject);
        orbitRangeObject.geometry.rotateX(90 * Math.PI / 180);
    },

    getOrbitRangeMaterial() {
        const orbitRangeColor = VisualisationService.getOrbitRangeColor();
        return new THREE.MeshStandardMaterial({
            color: orbitRangeColor,
            side: THREE.DoubleSide,
        });
    },

    removeOrbitRangeObject() {
        const vt = window['vt'];
        const scene = vt.getScene();
        const orbitRangeObjects = SpacekitService.getOrbitRangeObjects();
        orbitRangeObjects.forEach(object => scene.remove(object));
    },

    getOrbitRangeObjects() {
        const orbitRangeNames = ['perihelionOrbit', 'aphelionOrbit'];
        return orbitRangeNames.map(name => SpacekitService.getSceneObjectByName(name)).filter(orbitRangeObject => orbitRangeObject);
    },

    calculateDetectionPolarObject(objectData) {
        SpacekitService.removeDetectionPolarObjects();
        const detectionPolarCoordinates = SynodicCalculationService.getDetectionPolarPoints();
        const isMergedDetectionPolar = detectionPolarCoordinates.some(([x, y]) => x === null && y === null);
        if (!isMergedDetectionPolar) {
            SpacekitService.addDetectionPolarObjects(detectionPolarCoordinates, objectData);
            return;
        }

        const isDetectionPolarVisible = VisualisationService.getSettings().objectsSettings.detectionPolar.value;
        const {inside, outside} = SpacekitService.getMergedDetectionPolarVectors();       
        SpacekitService.createDetectionPolarObject(inside, isDetectionPolarVisible, 'detectionPolarMergedInside', objectData.magnitudeLimit, -90);
        SpacekitService.createDetectionPolarObject(outside, isDetectionPolarVisible, 'detectionPolarMergedOutside', objectData.magnitudeLimit, -90);
        SynodicCalculationService.changeElongationConeAppearance(isDetectionPolarVisible);
    },

    updateMergedDetectionPolar() {
        const {inside, outside} = SpacekitService.getMergedDetectionPolarVectors();
        SpacekitService.updateMergedDetectionPolarPosition('detectionPolarMergedInside', inside);
        SpacekitService.updateMergedDetectionPolarPosition('detectionPolarMergedOutside', outside);
    },

    updateMergedDetectionPolarPosition(name, vectors) {
        const existingMergedDetectionPolarObject = SpacekitService.getSceneObjectByName(name);
        existingMergedDetectionPolarObject.geometry.dispose();
        existingMergedDetectionPolarObject.geometry = new THREE.LatheGeometry(vectors, 64);
        existingMergedDetectionPolarObject.geometry.rotateZ(-90 * Math.PI/180)
    },

    getMergedDetectionPolarVectors() {
        const detectionPolarCoordinates = SynodicCalculationService.getDetectionPolarPoints();
        const [xEarthPosition] = SynodicCalculationService.getTransformedEarthObjectPosition();
        const xDifference = 1 - xEarthPosition;
        const detectionPolarPoints = detectionPolarCoordinates.map(([x, y]) => [x, xDifference >= 0 ? y - xDifference : y + -1 * xDifference]);
        const detectionPolarVectors = SpacekitService.setDetectionPolarPointsList(detectionPolarPoints);
        const detectionPolarNegativePoints = detectionPolarCoordinates.map(([x, y]) => [x, 1 - y]);
        const detectionPolarNegativeVectors = SpacekitService.setDetectionPolarPointsList(detectionPolarNegativePoints);
        const detectionPolarOnlyUpperVectors = SpacekitService.getFilteredDetectionPolarsVectors(detectionPolarVectors);
        const detectionPolarOnlyDownVectors = SpacekitService.getFilteredDetectionPolarsVectors(detectionPolarVectors.reverse());
        const detectionPolarNegativeOnlyUpperVectors = SpacekitService.getFilteredDetectionPolarsVectors(detectionPolarNegativeVectors);
        const detectionPolarNegativeOnlyDownVectors = SpacekitService.getFilteredDetectionPolarsVectors(detectionPolarNegativeVectors.reverse());
        const mergedVectorsInside = [...detectionPolarOnlyDownVectors, ...detectionPolarNegativeOnlyDownVectors.reverse()];
        const mergedVectorsOutside = [...detectionPolarOnlyUpperVectors, ...detectionPolarNegativeOnlyUpperVectors.reverse()];
        return {
            inside: mergedVectorsInside,
            outside: mergedVectorsOutside,
        }
    },

    getFilteredDetectionPolarsVectors(vectorsList) {
        const filteredVectors = [];
        for (const point of vectorsList) {
            if (point.x === null) {
                break;
            }
            filteredVectors.push(point);
        }
        return filteredVectors;
    },

    addDetectionPolarObjects(detectionPolarCoordinates, objectData) {
        const settings = VisualisationService.getSettings();
        const isDetectionPolarVisible = settings.objectsSettings.detectionPolar.value;
        const detectionPolarPointsList = SpacekitService.setDetectionPolarPointsList(detectionPolarCoordinates);
        const detectionPolarObjectsData = [
            {zRotationAngle: -90, xTransform: 0, name: 'detectionPolar'}, 
            {zRotationAngle: 90, xTransform: 1, name: 'detectionPolarNegative'}
        ];
        detectionPolarObjectsData.forEach(({zRotationAngle, xTransform, name}) => 
            SpacekitService.createDetectionPolarObject(name === 'detectionPolar' ? detectionPolarPointsList.reverse() : detectionPolarPointsList, 
                isDetectionPolarVisible, 
                name, 
                objectData.magnitudeLimit,
                zRotationAngle, 
                xTransform
            ));
        isDetectionPolarVisible && SynodicCalculationService.adjustDetectionPolarObjectPosition();
        SynodicCalculationService.changeElongationConeAppearance(isDetectionPolarVisible);
    },

    createDetectionPolarObject(detectionPolarPointsList, isDetectionPolarVisible, name,  magnitudeLimit, zRotationAngle = 0, xTransform = 0) {
        const scene = window['vt'].getScene();
        const detectionPolarGeometry = new THREE.LatheGeometry(detectionPolarPointsList, 64);
        const detectionPolarMaterial = SpacekitService.getDetectionPolarMaterial();
        const detectionPolar = new THREE.Mesh(detectionPolarGeometry, detectionPolarMaterial);
        detectionPolar.name = name;
        detectionPolar.visible = isDetectionPolarVisible;
        detectionPolar.renderOrder = 1;
        detectionPolar.magnitudeLimit = magnitudeLimit;
        scene.add(detectionPolar);
        zRotationAngle && detectionPolar.geometry.rotateZ(zRotationAngle * Math.PI/180);
        xTransform && (detectionPolar.position.x = xTransform);    
        if (detectionPolar.name == "detectionPolarNegative" || detectionPolar.name == "detectionPolarMergedInside")
            HUDService.createPolarLabel(detectionPolar,  null, null, ['center', 'top']);
    },

    setDetectionPolarPointsList(detectionPolarPointsData) {
        return detectionPolarPointsData.map(([x1, y1]) => new THREE.Vector2(x1, y1));
    },

    getDetectionPolarMaterial() {
        const detectionPolarColor = VisualisationService.getDetectionPolarColor();
        const detectionPolarOpacity = 1 - VisualisationService.getDetectionPolarTransparency();
        return new THREE.MeshStandardMaterial({
            color: detectionPolarColor,
            side: THREE.DoubleSide,
            transparent: true,
            opacity: detectionPolarOpacity,
        });
    },

    removeDetectionPolarObjects() {
        SynodicCalculationService.changeElongationConeAppearance();
        const detectionPolarObjects = SpacekitService.getDetectionPolarObjects();
        if (!detectionPolarObjects.length) {
            return;
        }
        const scene = window['vt'].getScene();
        detectionPolarObjects.forEach(object =>{HUDService.removePolarLabel(object);scene.remove(object);});
    },

    getDetectionPolarObjects() {
        const detectionPolarNames = ['detectionPolar', 'detectionPolarNegative', 'detectionPolarMergedOutside', 'detectionPolarMergedInside'];
        return detectionPolarNames.map(name => SpacekitService.getSceneObjectByName(name)).filter(detectionPolarObject => detectionPolarObject);
    },

    createGeostationaryOrbit(radius) {
        const points = new THREE.Path().absarc(0, 0, radius * SpacekitService.VIZ_UNITS, 0, Math.PI * 2).getPoints(90);
        const geometry = new THREE.BufferGeometry().setFromPoints(points);
        const material = new THREE.LineBasicMaterial({ color: SpacekitService.COLOR_CLOSEST_POINT });

        const orbit = new THREE.LineLoop(geometry, material);
        orbit.name = 'GeostationaryOrbit';

        return orbit;
    },

    addRingShape(options, segments) {
        segments = segments || 120;
      
        const shape = new THREE.Shape();
        shape.arc(0, 0, options.outerRadius, options.startAngle, options.endAngle);
        const points = shape.getPoints(segments);
        points.push(new THREE.Vector2(0, 0));
        const shape2 = new THREE.Shape(points);
      
        const shape3 = new THREE.Shape();
        shape3.arc(0, 0, options.innerRadius, options.startAngle, options.endAngle);
        const points2 = shape3.getPoints(segments);
        points2.push(new THREE.Vector2(0, 0));
        const shape4 = new THREE.Shape(points2);
        shape2.holes.push(shape4);
      
        const geometry = new THREE.ShapeGeometry(shape2, 64);
        const material = new THREE.MeshBasicMaterial({
          color: options.color,
          transparent: options.transparent,
          opacity: options.opacity,
          side: options.side
        });

        return new THREE.Mesh(geometry, material);
      },


      init(tool) {
        const vt = SpacekitService.buildSimulation(tool);
        if (vt){
            vt.spaceObjects = [];
            vt.smallObjects = [];
            vt.stop();
            window['vt'] = vt;
        }
        HUDService.initOrthographicInterface();
        HUDService.initInvertColorsShader();
        HUDService.setAnimationLoop();
        SpacekitService.buildBackgroundObjects(tool);
     },

      destroy() {
        let vt = window['vt'];
        vt.renderEnabled = false;

        const requestId = requestAnimationFrame(vt.animate);
        cancelAnimationFrame(requestId);

        const container = vt.getSimulationElement();
        while (container.lastChild)
            container.removeChild(container.lastChild);

        vt.getContext().objects.composer.dispose();
        vt.getContext().objects.renderer.dispose();
        vt.getContext().objects.scene.dispose;

        for (const el in vt) {
            if (el !== 'initialRenderComplete')
                vt[el] = null;  
        }

        vt = null;
    },
}

export default SpacekitService;
